<div *ngIf="count > 0">
    <div class="page-block">
        <!-- 数量小于6 -->
        <!-- <ul *ngIf="6 > count">
                <li (click)="paging(-1)"><img src="assets/images/page-pre.png"></li>
                <li (click)="paging(n)" [class.isHidden]="n>count" [class.isActive]="n==activeCount" *ngFor="let n of pageList">{{n}}</li>
                <li (click)="paging(-2)"><img src="assets/images/page-next.png"></li>
            </ul> -->
        <!-- 数量大于6 -->
        <ul class="page-number" *ngIf="total">
            <li style="line-height: 40px">共<b>{{total}}</b>条</li>
        </ul>
        <ul>
            <li (click)="paging(-1)"><img src="../../assets/images/page-pre.png"></li>
            <!-- <li (click)="paging(n)" *ngFor="let n of pageMoreList" [class.isActive]="n==activeCount">{{n}}</li>
                <li>...</li>
                <li (click)="paging(count)" [class.isActive]="count==activeCount">{{count}}</li> -->
            <li (click)="paging(n)" [class.isActive]="n==activeCount" *ngFor="let n of pageslist">{{n}}</li>
            <li (click)="paging(-2)"><img src="assets/images/page-next.png"></li>
        </ul>
        <ul *ngIf="count > 10" style="font-weight: bold;">跳转<input type="number" class="input" [(ngModel)]="pageNum" (keyup)="inputKeyup($event)">页</ul>
        <ul class="page-number">
            <li style="line-height: 40px">第<b>{{activeCount}}</b>页</li>
        </ul>

    </div>
</div>