// 平台认证
// 在期的认证续期调用续期接口
// 不在期的认证
import { Injectable } from "@angular/core";
import { DataApi } from "../provider/dataApi";
import { Department } from "./department";

@Injectable()
export class PlatformApply {
    /**
     * data.isCertified booble 认证是否通过
     * 
     */
  public data: any;
  constructor(public dataApi: DataApi, public department: Department) {}
  /**
   * 获取认证数据
   * @param refType 1：无需刷新；2：仅刷新当前数据；3：刷新所有数据
   * @returns
   */
  public async get(refType = 1) {
    let _refType = refType;
    if (_refType === 1 && this.data) return this.data;
    if (_refType === 2) _refType = 1;
    // 获取依赖数据
    let { apiData: departmentApiData } = await this.department.get(_refType);
    // 处理逻辑
    let data: any = {
      isCertified: false, // 认证在期
      isWillExpire: false, // 认证将过期
      applyStatus:0,// 申请状态 未申请 no_apply = 0,待审核 to_audit = 1,已通过 passed = 2,未通过 not_passed = 3
    };
    // 认证审核状态
    let isCertified = departmentApiData.is_certified;
    /** 认证结束时间 */
    let authEndtime = new Date(departmentApiData.authentication_endtime).getTime()
    /** 当前时间 */
    let nowTime = new Date().getTime();
    /** 获取申请数据 */
    let getApplyData = async ()=>{
      let getPlatformApplyFnStr = 'getPlatformApply'
      if(departmentApiData.apply_renewal){
          getPlatformApplyFnStr = 'authenticationPlatformApplyNew'
      }
      let platformApplyApiData = await this.dataApi[getPlatformApplyFnStr]()
      // 未申请 no_apply = 0,待审核 to_audit = 1,已通过 passed = 2,未通过 not_passed = 3
      data.platformApplyApiData = platformApplyApiData
      data.applyStatus = platformApplyApiData.status
      if(data.applyStatus === 2){
          data.applyStatus = 0
      }
    }
    // 如果认证通过且结束时间大于当前时间视为在期认证
    if(isCertified && (authEndtime >  nowTime)){
      data.isCertified = true
      // 如果结束时间减去当前时间小于30天视为将过期
      if(authEndtime - nowTime < 30 * 86400000){
        data.isWillExpire = true
        await getApplyData()
      }
    }else{
      data.isCertified = false
      await getApplyData()
    }
    this.data = data;
    // 返回数据
    return this.data;
  }
}
