import { Component, OnInit, ChangeDetectorRef, NgZone, Output, ViewChild } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { DataApi, Local, Constants, Config, Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Events } from '../../provider/eventService';
import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized, NavigationStart } from '@angular/router';
import { scrollTool } from "../../provider/scrollTool";
import { DialogModule } from 'primeng/dialog';
import { NoneVipAlertComponent } from "../none-vip-alert/none-vip-alert.component";
import { MatDialog } from "@angular/material/dialog";
import { HttpErrModelComponent } from "../http-err-model/http-err-model.component";
import { DepartmentSelfAlertComponent } from "../department-self-alert/department-self-alert.component";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
declare let window;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

   //是否打开产品介绍/请校招顾问联系我 弹窗
   @Output() isproductShowModel = false;
   @ViewChild('productModel', { static: true }) 
   productModel: ProductModelComponent;
   
  public config: Config;
  public isGoCollege = false;   //当前是否激活校园招聘导航
  public isGoChat = false;     //当前是否激活消息中心导航
  public isGoPosition = false; //当前是否激活招聘管理导航
  public isGoCompany = false; //当前是否激活企业中心导航
  public departmentJm = '';//企业极光号
  public isSendDepartment = false;
  public unRead = null;

  public isShow618Alert = false;

  public isShowPayUserDataOut = false;//是否显示用户会员快到期的提示弹窗
  public memberLastTime = "";

  public time618 = "00 天  00时 00分 00秒"
  public shouWeiChatCode = false;
  public wxCodeURL = '';
  public booth: any;
  public timer = null;

  public callSafeUpgradeModel = false;

  //是否显示体验版会员弹窗
  public isShowExperienceMember = false;

  // 体验版会员
  public member_trial = null
  public member_trial_vip = null

  // 未读消息数
  public tasktodo = 0;

  constructor(public dataApi: DataApi,
    public local: Local,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private zone: NgZone,
    public opera: Opera,
    private location: PlatformLocation,
    private activatedRoute: ActivatedRoute,
    public events: Events,
    public cd: ChangeDetectorRef,
    public scrolltool: scrollTool,
    public dialog: MatDialog,
    public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
    console.log('home-公共类加载');
    this.opera.updateParOrderCount();
    if (this.local.get(Constants.department)) {
      let department = this.local.get(Constants.department)
      if (department == undefined) {
        console.log('homegetLocalDepartment', department)
        // this.departmentJm=this.dataApi.getLocalDepartment().jm_username;
        this.events.publish('user:created'); //发送退出登录选项
      }
    }
    if (!this.local.get(Constants.accesstoken)) {
      this.events.publish('user:created'); //发送退出登录选项
    }

    // 监听浏览器返回/前进
    location.onHashChange(val => {
      history.go(0);
    })
    // 监听切换路由
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.opera.setRouterLocal(event.url);
      }
    });

    //收到新消息广播
    // this.events.subscribe('newMM:tabs', (data) => {
    //   console.log('tabs页面接受到了新消息广播-------MMtabs');
    //   console.log(data);

    //   if (!this.cd['destroyed']) {
    //     this.cd.detectChanges();//刷新数据
    //   }
    //   let arr = [];
    //   for (let n of this.config.msgList) {
    //     if (n.from != data.from) {
    //       arr.push(n);
    //     }
    //   }
    //   this.config.msgList = [data, ...arr];
    //   if (this.config.msgList.length > 0) {
    //     this.tasktodo = this.tasktodo + this.config.msgList.length;
    //     this.config.tasktodo = this.tasktodo + this.config.msgList.length;
    //   }
    // })

    this.getBoothList();//获取广告
    this.getProductList();
    this.isShow618Alert = false;

    this.getRecruitmentSelf();
    this.virtyDicVersion();
    this.getDepartment();

    // 检测用户是否可以领取体验会员
    // this.checkMamberTrial()

    if (this.local.get('departmentSelf')) {
      this.config.departmentSelf = JSON.parse(this.local.get('departmentSelf'))
    }
    //验证用户会员是否快到期提醒
    this.virtyMemberTimeOutAlert();

    this.events.subscribe('callSafeUpgradeNot', (data) => {
      console.log('监听极速电话联系升级');
      this.callSafeUpgradeModel = true;
    })
    this.events.subscribe('showHTTPErrModelNot', (data) => {
      // if(this.config.isShowHTTPErrModel) {return;}
      // this.config.isShowHTTPErrModel = true;
      // this.dialog.open(HttpErrModelComponent, {
      //   data: {
      //     type:'404',
      //     message:this.config
      //   }
      // }).afterClosed().subscribe((isConfirmed) => {});
    })
  }
  //获取企业信息
  getDepartment() {
    this.dataApi.getDepartment().then((dep) => {//通过tooken获取企业信息
      if (dep) {
        this.departmentJm = dep.jm_username;
        this.dataApi.setLocalDepartment(dep);
        this.config.department = dep;
        console.log('this.config.department', this.config.department);
        if (dep.member_info) {
          if (dep.member_info.product_code == "member_trial") {
            this.config.isMember_trial = true
          }
          //是否为公益会员
          if (dep.member_info.product_code == "member_welfare") {
            this.config.isMember_welfare = true
          }

        }
        this.dataApi.getPlatformApply().then((res: any) => {
          if (res) {
            this.config.lastIdentity = res;
            this.config.lastIdentityNavSatus = res.status;
            // if (dep.apply_renewal) {
            //   this.dataApi.authenticationPlatformApplyNew().then((newRes) => {
            //     if (newRes) {
            //       this.config.lastIdentityNavSatus = newRes.status;
            //     }
            //   }).catch(() => { })
            // }
          }
        }).catch(() => { })
      }
    }).catch(() => {
    })
  }

  //进入首页获取广告列表f
  getBoothList() {
    this.dataApi.getBoothList().then((res: any) => {
      console.log('广告-----------------', res);
      console.log(res);
      if (res) {
        for (let val of res) {
          //侧面图
          if (val.alias == "leftnav") {
            this.booth = val;
          }
        }
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  //点击跳转活动详情（双选会/宣讲会）
  goActivityDetails(data) {
    if (data.activity_type == "milkround") {
      this.dataApi.getMilkDetails(data.activityid).then((rres) => {
        console.log("获取到会议信息--------------------------CollegeHome");
        console.log(rres);
        if (rres) {
          this.dataApi.lastCollegeAuthenticationIdentity(rres.collegeid).then((res: any) => {
            console.log("获取到高校信息--------------------------CollegeHome");
            console.log(res);
            if (res) {
              this.dataApi.getPlatformApply().then((tres) => {
                console.log('最后一次认证信息');
                console.log(tres);
                if (tres) {
                  this.config.lastIdentity = tres;
                  console.log(this.config.lastIdentity);
                  this.config.lastIdentity = tres;
                  if (this.config.lastIdentity) {
                    if (this.config.lastIdentity.status == 2) {
                      if (res.status == 2 || res.is_activity) {
                        this.local.set(Constants.mrid, data.activityid);
                        this.router.navigateByUrl('/home/college-milkdetails');
                      } else {
                        this.toastr.warning('请先通过该校合作申请');
                      }
                    } else {
                      this.opera.remindCould();
                    }
                  }
                }

              })
            }
          }).catch((err) => {
            console.log(err);
          })
        }
      }).catch((err) => {
        // console.log(err);
        this.toastr.warning('双选会已删除或不存在')
      })
    } else if (data.activity_type == "campustalk") {
      // this.navCtrl.push('CampusInfo',{ctid:data.activityid});
    }
  }

  ngOnInit() {
    this.getUrl();//初始化时获取路由信息切换子路由
    setTimeout(() => { this.config.isLogin = false; })
    this.opera.checkPlatformIdentity();//检测企业认证信息
    this.getTodoList();
    this.events.subscribe('isReadNotice', (data) => {
      if (this.config.NavunReadNum > 0) {
        this.getTodoList();
      }
    });

    this.dataApi.getMsgUnViewCount().then((res: any) => {
      this.opera.loadiss();
      if (res) {
        this.config.tasktodo = res.value;
        this.tasktodo = this.config.tasktodo;
      }
    }).catch((error) => {
      this.opera.loadiss()
      console.log('getWorkData', error)
      // this.toastr.error(error);
    });

  }

  //获取当前路由地址
  getUrl() {
    //console.log("打印路由-------------");
    //console.log(this.activatedRoute.children[0]);
    //初始化时判断路由切换页面
    if (this.activatedRoute.children[0]) {
      this.config.isNavChildActive = this.activatedRoute.children[0].snapshot.routeConfig.path;
      this.router.navigate([this.config.isNavChildActive], { relativeTo: this.activatedRoute });
      let n = this.config.isNavChildActive.indexOf('-');
      if (n > -1) {
        this.config.isNavActive = this.config.isNavChildActive.slice(0, n);
      }
    } else {
      this.router.navigateByUrl('home/workSpase');
    }
  }
  //判断切换的导航路由
  goChild(data, txt) {
    this.config.isNavActive = data;
    this.config.isNavChildActive = txt;
    if (txt == 'company-authentication') {
      this.opera.goplatformIdentity();
    }
  }
  //去引导页
  goGuid() {
    this.router.navigateByUrl('guide');
  }

  getTodoList() {
    this.dataApi.getTodoList({ 'view': false }).then((res: any) => {
      console.log(res)
      if (res) {
        this.config.NavunReadNum = res.count;
      }
    }).catch((err) => {

    })
  }
  //
  gomPage() {
    this.dataApi.getPlatformApply().then((res: any) => {
      if (res) {
        this.config.lastIdentity = res;
        if (res.status == 2) {
          this.router.navigateByUrl('/home/company-authentication-suc');
        } else if (res.status == 1) {
          this.router.navigateByUrl('/home/company-authentication-wait');
        } else if (res.status == 3) {
          this.router.navigateByUrl('/home/company-authentication');
        } else {
          this.router.navigateByUrl('/home/company-authentication');
        }
      }

    }).catch((err) => {
      this.router.navigateByUrl('/home/company-authentication');
    })
  }

  gotoPage(page) {
    this.baiduFormat(page);
    this.scrolltool.initZero();
    // this.getDepartment();
    if (page == "airCampusDetail") {
      this.gotoAirCanpCheckAU(page);
      return;
    }
    if (page == "tool-campus") {
      this.config.payVipHistoryPage = null
    }

    if (page == 'searchPeople') {
      this.goSearch();
      return;
    }
    if (page == 'college-milkround1' || page == 'college-milkround') {
      if (page == 'college-milkround1') {
        this.local.set('isAir', 2)
      } else {
        this.local.set('isAir', 1);
      }
      this.removeMilkroundStorage();
      this.events.publish('isChangeRouter', false);
    }
    if (page == "rights") {
      this.router.navigateByUrl('home/rights');
      this.opera.initConfig('rights', 'rights');
      return;
    }
    if (page == "position-manage") {
      this.router.navigateByUrl('home/position-manage');
      this.opera.initConfig('position', 'position-manage');
      return;
    }
    if (page == 'college-milkround1') {
      this.router.navigate(['home/college-milkround'], {
        queryParams: {
          isAir: true
        }
      });
    }

    this.router.navigateByUrl('/home/' + page);
    // if(page == 'position-resume'){
    //   //简历管理判断是否为会员
    //   this.opera.checkMember((isPay)=>{
    //     console.log(isPay);
    //     if(!isPay){
    //       this.opera.checkMemberAlertWithType("noneVip_voice_call");
    //     }else{
    //       this.router.navigateByUrl('/home/' + page);
    //     }
    //   })
    // }else {
    //   this.router.navigateByUrl('/home/' + page);
    // }
  }

  removeMilkroundStorage(){
    this.local.remove('milkround_college_name_search');
    this.local.remove('milkround_dateSattus_search');
    this.local.remove('milkround_school_type_search');
    this.local.remove('milkround_dateSattus_search_start');
    this.local.remove('milkround_dateSattus_search_end');
    this.local.remove('milkround_type_search');
  }
  //airCampusDetail
  //跳转到空中宣讲会并检测用户权限
  gotoAirCanpCheckAU(page) {
    let that = this;
    that.router.navigateByUrl('/home/airCampList');
  }

  goSearch() {
    this.scrolltool.initZero();
    this.events.publish('isShowSearch', false);
    this.router.navigateByUrl('/home/searchPeople');
  }
  gomePage() {
    // this.router.navigateByUrl('/home/company-status-two');
    if (this.config.department.member_info || this.config.department.quota.product_component_info.length > 0) {
      this.router.navigateByUrl('/home/tool-campus');
    } else {
      this.router.navigateByUrl('/home/tool-campus');
    }
  }
  goPeople() {
    this.router.navigateByUrl('/home/searchPeople');
  }
  checkTime(i) {
    if (i <= 10) {
      i = "0" + i;
    }
    return i;
  }

  //获取公司关注公众号
  getCompanyQRCode() {
    let accountid = this.local.get('accountid');
    this.dataApi.getComopanyIsBingWeiChat(accountid).then((res: any) => {
    }).catch((error) => {
      if (this.local.get('department')) {
        let department = this.local.get('department');
        let departmentJSON = JSON.parse(department);
        let departmentid = departmentJSON.accountid;
        let suiji = this.RondomPass(24);
        let data = { departmentid: departmentid };
        console.log("WeChatCode", data);
        this.dataApi.getWeiChatCode(data).then((res: any) => {
          console.log("getWeiChatCode", res);
          let url = res.value
          if (res.value) {
            this.wxCodeURL = url;
            this.config.wxCodeURL = url
            this.shouWeiChatCode = true;
            this.virtyUerBingWeiChat();
          }
        }).catch((err) => {
          console.log("weChatQRCodeErr", err);
        })
      }
    });
  }
  //随机数生成
  RondomPass(number) {
    var arr = [];
    var arr1 = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    for (var i = 0; i < number; i++) {
      var n = Math.floor(Math.random() * 10);
      // arr[i] =arr1[n] ;
      arr[i] = '0';
    }
    let val = arr.join("");
    return val;
  }

  virtyUerBingWeiChat() {
    if (this.wxCodeURL == '') {
      this.toastr.error("获取微信公众号失败");
      return;
    }
    var that = this;
    let accountid = this.local.get('accountid');
    let index = 0;
    //开启循环：每秒出现一次提示框
    this.timer = setInterval(function () {
      if (!that.shouWeiChatCode) {
        if (that.timer) {
          clearInterval(that.timer);
        }
      }
      console.log('that.shouWeiChatCode', that.shouWeiChatCode)
      //检测企业是否绑定微信
      that.dataApi.getComopanyIsBingWeiChat(accountid).then((res: any) => {
        console.log('getComopanyIsBingWeiChat', res);
        that.shouWeiChatCode = false;
        clearInterval(that.timer);
        return;
      }).catch((error) => {
      });
    }.bind(this), 1500);
  }

  getProductList() {
    this.dataApi.departmentList({ product_category: "recharge" }).then((res: any) => {
      if (res) {
        for (let item of res.list) {
          if (item.recharge_category == "voice_call") {
            this.config.ProductListCode.voice_call_code = item.code;
          }
          if (item.recharge_category == "sms_bulkinvitation") {
            this.config.ProductListCode.sms_bulkinvitation_code = item.code;
          }
          if (item.recharge_category == "activity_invitation") {
            this.config.ProductListCode.activity_invitation_code = item.code;
          }
          if (item.recharge_category == "position_refresh") {
            this.config.ProductListCode.position_refresh_code = item.code;
          }
          if (item.recharge_category == "notice_student_sms") {
            this.config.ProductListCode.notice_student_sms_code = item.code;
          }
        }
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  //关闭关注公众号二维码的回掉，清除定时器监听扫码关注请求
  onHideQrCode() {
    console.log("onHideQrCode");
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  //获取左侧栏目的校招顾问
  getRecruitmentSelf() {
    this.dataApi.getRecruitmentSelf().then((res: any) => {
      console.log('获取左侧栏目的校招顾问', res);
      if (res) {
        this.config.departmentSelf = res;
        this.local.set('departmentSelf', JSON.stringify(res))
      }
    }).catch((error) => {
      console.error(error);
    });
  }
  //验证字典的版本
  virtyDicVersion() {
    this.dataApi.virtyDicVersion().then((res: any) => {
      console.log('virtyDicVersion', res);
      if (res) {
      }
    }).catch((error) => {
    });
  }

  //验证用户快到期提醒
  virtyMemberTimeOutAlert() {
    if (this.dataApi.getLocalDepartment()) {
      let department = this.dataApi.getLocalDepartment()
      if (department.member_info) {
        let nowData = new Date();
        //活动截止时间
        let overData = new Date(department.member_info.endtime);
        //60天到期提醒
        let spaceBe = this.config.memberEndTime * 24 * 60 * 60 * 1000;
        if ((overData.getTime() - nowData.getTime()) > spaceBe) {
          console.log('会员未到期');
        }
        else if (nowData.getTime() > overData.getTime()) {
          console.log('会员已经到期了');
        }
        else {
          console.log('会员快到期了,不足两个月')
          let isShowMemberTimeOut = this.local.get('isShowMemberTimeOut')
          if (!isShowMemberTimeOut) {
            let long = (overData.getTime() - nowData.getTime()) / 1000 / 60 / 60 / 24;
            this.memberLastTime = long.toFixed(0);
            this.dataApi.checkMemberCheckV2().then((res: any) => {
              console.log('会员快到期了,但是有新的会员', res);
              if (res.has_member_order) {
              }
              else {
                //非体验会员 才有会员到期提醒
                if (department.member_info.product_code != 'member_trial') {
                  this.isShowPayUserDataOut = true;
                }
              }
            });
          }
        }
      }
    }
  }

  //付费会员到期弹窗消失
  onHidePayOut() {
    this.isShowPayUserDataOut = false;
    this.local.set("isShowMemberTimeOut", "isShowMemberTimeOut")
  }

  onHidecallSafeUpgradeModel() {
    this.callSafeUpgradeModel = false;
  }
  memberOutClick(index) {
    this.isShowPayUserDataOut = false;
    this.router.navigateByUrl('/home/tool-campus');
  }
  baiduFormat(page) {
    if (page == "workSpase") {
      this.opera._hmtPush("通用", "左侧导航栏", "工作台")
    }
    else if (page == "position-manage") {
      this.opera._hmtPush("通用", "左侧导航栏", "职位管理")
    }
    else if (page == "college-resume") {
      this.opera._hmtPush("通用", "左侧导航栏", "推荐人才")
    }
    else if (page == "searchPeople") {
      this.opera._hmtPush("通用", "左侧导航栏", "搜索人才")
    }
    else if (page == "position-resume") {
      this.opera._hmtPush("通用", "左侧导航栏", "简历管理")
    }
    else if (page == "college-milkround") {
      this.opera._hmtPush("通用", "左侧导航栏", "双选会/宣讲会")
    }
    else if (page == "airCampusDetail") {
      this.opera._hmtPush("通用", "左侧导航栏", "空中宣讲会")
    }
    else if (page == "college-campustalk") {
      this.opera._hmtPush("通用", "左侧导航栏", "高校云")
    }
    else if (page == "notification-center") {
      this.opera._hmtPush("通用", "左侧导航栏", "通知中心")
    }
    else if (page == "tool-campus") {
      this.opera._hmtPush("通用", "左侧导航栏", "校招工具")
    }
    else if (page == "company-details") {
      this.opera._hmtPush("通用", "左侧导航栏", "我的资料")
    }
  }
  colseExMemberModel() {
    this.isShowExperienceMember = false
    this.config.isOpenExperienceMember = true
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  showExModel() {
    this.isShowExperienceMember = true
    this.config.isOpenExperienceMember = false
  }
  //检查用户的体验版会员
  checkMamberTrial() {
    this.dataApi.checkTrial().then((res: any) => {
      if (res) {
        console.log('checkMamberTrial', res);
        if (!res.value) {
          this.isShowExperienceMember = true;
          setTimeout(() => {
            this.dialog.closeAll();
          }, 500)
        }
      }
    }).catch((err) => {
      console.log(err);
    })
    this.dataApi.departmentCode('member_trial').then((res: any) => {
      if (res) {
        this.member_trial = res
        let newArr = []
        this.member_trial.product_infos.map((item) => {
          if (item.alias == 'voice_call') {
            item.ex_ico = '../../assets/images/ex3.png'
          } else if (item.alias == 'position_refresh') {
            item.ex_ico = '../../assets/images/ex4.png'
          }
          else {
            item.ex_ico = item.ico
          }
          if (item.alias == 'member_day') {
            this.member_trial_vip = item
          }
          if (item.alias != 'member_day') {
            newArr.push(item);
          }
        })
        this.member_trial.product_infos = newArr
        console.log('获取产品详情', this.member_trial)
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  //领取体验版会员
  getExMember() {

    let model = {
      member_product_code: "member_trial",
      online_packages: [],
      balance: 0,
      need_pay: 0,
    };
    this.opera.loading()
    this.dataApi.paymentV2CreatorderByMoney(model).then((res: any) => {
      if (res) {
        console.log('paymentV2CreatorderByMoney', res);
        this.dataApi.paymentV2CreatorderV2(res.value).then((res: any) => {
          // this.toastr.success('领取成功')
          // this.config.isOpenExperienceMember = false
          this.isShowExperienceMember = false
          // this.config.isOpenExperienceMember = false
          this.getDepartment()
          this.dialog.open(DepartmentSelfAlertComponent, {
            data: { title: "恭喜您领取成功，体验过程中有任何问题可随时联系客服帮您解答", type: 'member_trial' }
          }).afterClosed().subscribe((isConfirmed) => { });
          this.opera.loadiss();
          setTimeout(() => {
            this.config.isOpenExperienceMember = false
          }, 300)
        }).catch((err) => {
          this.opera.loadiss();
          this.toastr.error(err)
        })
      }
    }).catch((err) => {
      this.opera.loadiss()
      console.log(err);
      this.toastr.error(err)
    })
  }
  dragFunc() {
    var Drag = document.getElementById('experienceIcon');
    console.log('dragFunc', Drag)
    setTimeout(() => {
      var Drag = document.getElementById('experienceIcon');
      console.log('dragFunc', Drag)

      Drag.onmousedown = function (event) {
        var ev = event || window.event;
        event.stopPropagation();
        var disX = ev.clientX - Drag.offsetLeft;
        var disY = ev.clientY - Drag.offsetTop;
        document.onmousemove = function (event) {
          var ev = event || window.event;
          Drag.style.left = ev.clientX - disX + "px";
          Drag.style.top = ev.clientY - disY + "px";
          Drag.style.cursor = "move";
        };
      };
      Drag.onmouseup = function () {
        document.onmousemove = null;
        Drag.style.cursor = "default";
      };
    }, 600)
  };
  copyText(text) {
    var aux = document.createElement("input");
    var _content = text;
    aux.setAttribute("value", _content);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("Copy");
    document.body.removeChild(aux);
    this.toastr.success('复制成功')

  }
  boothClick() {
    if (this.booth) {
      if (this.booth.links) {
        window.open(this.booth.links, '_blank');
      }
    }
  }
  //查看权益
  checkQuanyi() {
    this.isShowExperienceMember = false;
    this.router.navigate(['home/tool-campus']);

  }

  //点击产品
  product(alias,type){
    this.productModel.initData(alias,type,true);
    this.isproductShowModel = true;
  }

  productModelHidden(event){    
    this.isproductShowModel = false;
  }

}
