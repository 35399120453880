<div style="background-color: #ffffff" class="homepage-block">
    <router-outlet></router-outlet>
    <app-model-send-msg #sengMsgModel [SengMsgModel]='SengMsgModel' [tconfig]='config' (onVoted)="Modeldismiss($event)" [isShow]="modelShowSengMsg"></app-model-send-msg>
    <app-voice-call-model #voiceCallModel [isShow]="isShowVoiceCallModel" (modelHidden)="voiceCallModelHidden($event)"></app-voice-call-model>
    <!-- 3.0 -->
    <div class="container">
        <div class="tip" *ngIf="isshow_tel_phone_upgrade_model">
            <div class="content">由于监管要求， 运营商在5月10号上线“虚拟号业务固话拦截系统”，导致固话拨打虚拟号会被拦截限制，我们建议您在呼出的的业务场景中尽量使用手机号码，非常感谢您的理解和支持。</div>
            <img class="closeModel" src="../../assets/images/v3/tel_phone_upgrade_close.png" (click)="closeTelPhoneUpgradeModel()" />
        </div>

        <!-- banner -->
        <div class="layui-carousel" id="test1" *ngIf="bannerList.length>0">
            <div carousel-item>
                <div *ngFor="let n of bannerList;let i = index;" (click)="bannerImageClick(n)">
                    <img class="swiper-slide bannerImage" id={{i}} src="{{n.image_url}}" />
                </div>
            </div>
        </div>

        <!-- 看板 -->
        <div class="kanbanContainer">
            <div class="logo"><img src="/assets/images/rights/kanbanLogo.png"></div>
            <div class="cell">
                <div class="name">校园招聘数据看板</div>
                <div class="time">更新时间：{{kanbanData.updateTime}}</div>
            </div>
            <div class="rightMain">
                <div class="item">
                    <div class="title">求职者人数</div>
                    <div class="number"><span>{{kanbanData.value1}}</span>人</div>
                </div>
                <div class="item">
                    <div class="title">总用人单位</div>
                    <div class="number"><span>{{kanbanData.value2}}</span>家</div>
                </div>
                <div class="item">
                    <div class="title">总发布职位</div>
                    <div class="number"><span>{{kanbanData.value3}}</span>个</div>
                </div>
                <div class="item">
                    <div class="title">总岗位数</div>
                    <div class="number"><span>{{kanbanData.value4}}</span>个</div>
                </div>
            </div>
        </div>

        <!-- 企业信息、6大模块 -->
        <div class="enterpriseMain">
            <div class="left">
                <div class="top" *ngIf="myDepartment">
                    <div class="logo mw_mc">
                        <img *ngIf="myDepartment.logo" [src]="myDepartment.logo">
                        <img *ngIf="!myDepartment.logo" src="/assets/images/rights/departmentLogo.png">
                    </div>
                    <div class="rightName">
                        <div class="name" *ngIf="myDepartment.name">
                            {{myDepartment.name}}
                            <!-- <img title="已认证" *ngIf="config.lastIdentityNavSatus == 2" src="/assets/images/rights/authentication.png"> -->
                            <div class="state authentication mw_mc" *ngIf="platformApplyData && platformApplyData.isCertified">
                                <span *ngIf="lastModify && (lastModify.status == 0 || lastModify.status == 2)" (click)="goauthentication()">去修改</span>
                                <span *ngIf="lastModify && lastModify.status == 1">修改审核中</span>
                                <span *ngIf="lastModify && lastModify.status == 3" style="color: #FD654A;" (click)="goauthentication()">修改审核未通过</span>
                            </div>
                            <div class="state Failed mw_mc" (click)="gotoRenzheng()" *ngIf="platformApplyData && !platformApplyData.isCertified&&platformApplyData.applyStatus === 3">认证未通过</div>
                            <div class="state authentication mw_mc" (click)="gotoRenzheng()" *ngIf="platformApplyData && !platformApplyData.isCertified&&platformApplyData.applyStatus === 0">立即认证</div>
                            <div class="state authentication mw_mc" *ngIf="platformApplyData && !platformApplyData.isCertified&&platformApplyData.applyStatus === 1">认证中</div>
                        </div>
                        <div class="text">{{myDepartment.employer_type_text}}｜{{myDepartment.scale_text}}｜{{myDepartment.industry_text}}</div>
                    </div>
                </div>
                <div class="perfect">
                    <div class="title">公司完善度</div>
                    <div class="stripBox" *ngIf="myDepartment && myDepartment.level">
                        <div class="strip">
                            <div class="colorCell" [ngStyle]="{'width':myDepartment.level+'%'}"></div>
                        </div>
                        <div class="percentage" *ngIf="myDepartment.level">{{myDepartment.level}}%</div>
                        <div class="goperfect" (click)="goComHome()">去完善<img src="/assets/images/v3/goperfect.png"></div>
                    </div>
                </div>
                <!--会员信息-->
                <div class="vipBox" *ngIf="isShowVip&&myDepartment">
                    <div class="icon">
                        <img src="/assets/images/v3/yellowVip.png">
                    </div>
                    <div class="surplusTime">
                        <div class="surplus1">您的基础版会员有效期 {{myDepartment.member_info.days}}天</div>
                        <div class="surplus2"><img src="/assets/images/v3/time.png">{{myDepartment.member_info.endtime_text}} 到期</div>
                    </div>
                    <div class="btn blueStreamer" (click)="goPublish($event)"><img src="/assets/images/v3/push.png">发布职位</div>
                </div>
                <!--非会员-->
                <div class="vipBox" *ngIf="!isShowVip && !vipExperience">
                    <div class="icon">
                        <img src="/assets/images/v3/yellowVipActive.png">
                    </div>
                    <div class="surplusTime">
                        <div class="surplus3">您还不是工作啦会员</div>
                    </div>
                    <div class="btn blueStreamer" (click)="goPublish($event)"><img src="/assets/images/v3/push.png">发布职位</div>
                </div>
                <!--体验会员-->
                <div class="vipBox" *ngIf="!isShowVip && vipExperience">
                    <div class="icon">
                        <img src="/assets/images/v3/yellowVip.png">
                    </div>
                    <div class="surplusTime">
                        <div class="surplus1">您的会员权益体验限期{{vipExperiencedays}}天</div>
                        <div class="surplus2"><img src="/assets/images/v3/time.png">{{myDepartment.memberTrail.endtime | date:'yyyy-MM-dd HH:mm:ss'}} 到期</div>
                    </div>
                    <div class="btn blueStreamer" (click)="goPublish($event)"><img src="/assets/images/v3/push.png">发布职位</div>
                </div>
            </div>
            <div class="right">
                <div class="list">
                    <div class="item" (click)="goRouter('position-resume','')">
                        <div class="item-name">
                            <div class="name">已收到简历</div>
                        </div>
                        <div class="item-main">
                            <div class="icon"><img src="/assets/images/v3/homeIcon01.png"></div>
                            <div class="right-number">
                                <div class="number">
                                    <div class="_nub">{{workData.delivery.total==0?'0':workData.delivery.total}}</div>
                                    <div class="upIcon" *ngIf="workData.delivery.today > 0"><img src="/assets/images/v3/up.png"></div>
                                </div>
                                <div class="added">新收简历：<span>{{workData.delivery.today}}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="item" (click)="goRouter('position-manage','')">
                        <div class="item-name">
                            <div class="name">在招职位</div>
                            <div class="righttext" (click)="goPublish($event)"><img src="/assets/images/v3/pushposition.png">发布职位</div>
                        </div>
                        <div class="item-main">
                            <div class="icon"><img src="/assets/images/v3/homeIcon02.png"></div>
                            <div class="right-number">
                                <div class="number">
                                    <div class="_nub">{{workData.position.total==0?'0':workData.position.total}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item" (click)="goRouter('chat-collection','0')">
                        <div class="item-name">
                            <div class="name">对我感兴趣</div>
                        </div>
                        <div class="item-main">
                            <div class="icon"><img src="/assets/images/v3/homeIcon03.png"></div>
                            <div class="right-number">
                                <div class="number">
                                    <div class="_nub">{{workData.position_view.total==0 ? config.isMember_welfare?'0':0 :workData.position_view.total}}</div>
                                    <div class="upIcon"><img src="/assets/images/v3/up.png"></div>
                                </div>
                                <div class="added">新增：<span>{{workData.position_view.today}}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="item" (click)="goRouter('college-milkround','')">
                        <div class="item-name">
                            <div class="name">我的双选会</div>
                            <div class="righttext" *ngIf="workData.milkround?.total_offline !=0" (click)="gotoMilkroundList('position-milk','2',$event)">校招活动邀请</div>
                        </div>
                        <div class="item-main">
                            <div class="icon"><img src="/assets/images/v3/homeIcon04.png"></div>
                            <div class="right-number">
                                <div class="number">
                                    <div class="_nub">{{workData.milkround?.total}}</div>
                                </div>
                                <div class="added">新增双选会：<span>{{workData.milkround?.can_apply}}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="item" (click)="goRouter('position-campus','2')">
                        <div class="item-name">
                            <div class="name">我的宣讲会</div>
                        </div>
                        <div class="item-main">
                            <div class="icon"><img src="/assets/images/v3/homeIcon05.png"></div>
                            <div class="right-number">
                                <div class="number">
                                    <div class="_nub">{{workData.campustalk}}</div>
                                </div>
                                <div class="added">新增宣讲会：<span></span></div>
                            </div>
                        </div>
                    </div>
                    <div class="item" (click)="goRouter('notification-center','')">
                        <div class="item-name">
                            <div class="name">未读通知</div>
                        </div>
                        <div class="item-main">
                            <div class="icon"><img src="/assets/images/v3/homeIcon06.png"></div>
                            <div class="right-number">
                                <div class="number">
                                    <div class="_nub">{{config.tasktodo>=100?'99+':config.tasktodo}}</div>
                                    <!-- <div class="upIcon"><img src="/assets/images/v3/up.png"></div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 推荐 -->
        <div class="recommend">
            <div class="title">
                <div class="tj">为您推荐</div>
                <div class="search">
                    <input type="text" placeholder="请输入简历ID、职位、学校、专业等关键词" [(ngModel)]="searchPeopleText" (keyup)="inputKeyup($event)">
                    <div class="icon" (click)="goSearch()"><img src="/assets/images/v3/searchIcon.png"></div>
                </div>
            </div>
            <div class="list">
                <div class="item" (click)="goDetails(n,n.ignore)" *ngFor="let n of recommendList | slice:0:9;let i = index;">
                    <div class="main">
                        <div class="introduce">
                            <div class="logo">
                                <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}" />
                                <img *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png" />
                                <img *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png" />
                                <img *ngIf="!n.logo&&!n.gender" src="assets/images/male.png" />
                            </div>
                            <div class="text">
                                <div class="name">
                                    {{n.talent_name | clearSpace}}
                                    <span *ngIf="n.new_talent">新用户</span>
                                    <span *ngIf="n.is_called">已联系</span>
                                    <span *ngIf="n.is_push">已推送</span>
                                </div>
                                <div class="college">{{n.graduate_university}}</div>
                                <div class="major">{{n.major_text}} | {{n.education_text}} | {{n.year==0?"往届":n.year+'届'}}</div>
                            </div>
                        </div>
                        <div class="lable">
                            <div *ngIf="n.skills.length>0">
                                <span *ngFor="let skil of n.skills | slice:0:3;let i = index;">{{skil.text}}</span>
                            </div>
                        </div>
                        <div class="bottomBtns">
                            <div class="btn" (click)="ShowPhoneModel(n,$event)">
                                <img src="/assets/images/v3/speedIcon.png" /> <span>极速电话联系</span>
                            </div>
                            <div class="btn active" (click)="alertSengMSG(n,$event)">
                                <img src="/assets/images/v3/pushIcon.png" /> <span>职位推送</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- 分页部分 -->
<div class='paging-block' [class.isPageHeight]="recommendList.length>0">
    <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
</div>



<!--双十一弹窗广告 -->
<p-dialog [contentStyle]="{'padding':'0',border:'0','background':'none'}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'box-shadow':'unset'}" [modal]="true" [showHeader]="false" [(visible)]="pro_tanchuangModel">
    <div class="flex_c" *ngIf="pro_tanchuang">
        <img style="width: 550px;height: auto;cursor: pointer" (click)="customClick()" src="{{pro_tanchuang.image_url}}" />
    </div>
    <img class="closeModelAc" (click)="closeCustom()" src="../../assets/images/icon_guanbi_model.png" />
</p-dialog>

<p-dialog id="aboutLaCoin" [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="false" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset','width':600,'height':400,'minHeight':400}" [modal]="true" [showHeader]="false"
    [(visible)]="isShowSecurityUpgrade">
    <div class="UpgradeModel">
        <div class="flex_s UpgradeTitle">
            <span>工作啦招聘信息安全升级通知</span>
            <img src="../../assets/images/down_close.png" (click)="upgradeTitleComit()" />
        </div>
        <p class="aboutLaCoinContent" style="margin-top: 25px">亲爱的用户，您好。</p>
        <p class="aboutLaCoinContent">为建设更安全健康的招聘求职环境，保护招聘者和求职者的信息安全，工作啦已对如下功能进行升级。</p>
        <p class="aboutLaCoinContent">1、简历水印功能:当您将简历导出至本地时，简历将以增加企业水印的格式保存，简历不包含“联系”方式信息，您可进入工作啦平台内查看求职者的联系方式。</p>
        <p class="aboutLaCoinContent">2、搜索人才和简历管理新增简历ID查询功能:您可输入学生的简历ID搜索对应的学生进行联系。</p>
        <p class="aboutLaCoinContent">感谢您对工作啦的支持，祝您工作顺利。</p>
        <div class="UpgradeTitleComit" (click)="upgradeTitleComit()">我知道了</div>

    </div>
</p-dialog>

<!--热力值弹窗-->
<!--<p-dialog id="aboutHot" [width]="522" [height]="466" [minHeight]="466" [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}"  [modal]="true" [showHeader]="false" [(visible)]="aboutHotValue">-->
<!--<div style="width: 522px;height: 466px;background-color: white;overflow: hidden;">-->
<!--<img style="width: 522px;height: 466px;" src="../../assets/images/work/hot_popup.png" />-->
<!--</div>-->
<!--</p-dialog>-->

<!-- 关注公总号检查 -->
<p-dialog [closeOnEscape]="false" [showHeader]="false" [dismissableMask]="false" [(visible)]="showWeChatCode" [contentStyle]="{'border-radius':'6px','padding':0,'width':478,'height':466,'minHeight':466}" [modal]=true>
    <div class="selteTypeModel">
        <div class="selteTypeModelTitle">
            <img src="/assets/images/rights/weChat.png">微信扫码绑定
        </div>
        <div class="text">请扫码绑定微信公众号，绑定成功后可扫码一键登录，同时可及时获悉您的高校合作、双选会、宣讲会审核结果和获取简历通知</div>
        <div class="wxBorder">
            <img *ngIf="weChatCode" src={{weChatCode}} />
        </div>
        <div class="btn blueStreamer" (click)="closeCheckWeiChat()">我知道</div>
    </div>
</p-dialog>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>
<!-- 认证不通过-->
<p-dialog [contentStyle]="{'padding':'0',border:'0','border-radius':'6px'}" [dismissableMask]="false" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}" [modal]="true" [showHeader]="false" [(visible)]="showAuditModel">
    <div class="dialog_close_btn" style="top: 19px;right: 32px;" (click)="showAuditModel=false"><img src="/assets/images/rights/contactMeClos.png"></div>
    <div style="width: 466px;padding-bottom: 30px;background-color: white;overflow: hidden;">
        <div class="auditModel_title">审核通知</div>
        <div class="d_f jc_c" style="padding-top: 31px;">
            <img class="auditModel_icon" src="/assets/images/v3/positionPushTip.png">
            <div class="auditModel_status">审核未通过</div>
        </div>
        <div class="auditModel_explain">未通过原因：{{auditRemark}}</div>
        <div class="ta_c auditModel_more">如您在认证中遇到问题，可致电：4006611396</div>
        <div class="d_f jc_c" style="padding-top: 11px;">
            <div class="custom_btn_primary" (click)="gotoRenzheng()">重新提交</div>
            <div class="custom_btn_plain" (click)="showAuditModel=false">取消</div>
        </div>
    </div>
</p-dialog>
<!-- 领取体验会员-->
<p-dialog [contentStyle]="{'padding':'0',border:'0','border-radius':'6px'}" [dismissableMask]="false" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}" [modal]="true" [showHeader]="false" [(visible)]="memberTrialModelShow">
    <div class="dialog_close_btn" style="top: 19px;right: 32px;" (click)="memberTrialModelShow=false"><img src="/assets/images/rights/contactMeClos.png"></div>
    <div style="width: 466px;padding-bottom: 30px;background-color: white;overflow: hidden;">
        <div class="auditModel_title">提示</div>
        <div class="d_f jc_c" style="padding-top: 31px;">
            <div class="auditModel_status" style="color:#000">尊贵的用户，您有一份体验会员待领取！</div>
        </div>
        <div class="d_f jc_c" style="padding-top: 31px;">
            <div class="custom_btn_primary" (click)="getMemberTrialGet()">立即领取</div>
            <div class="custom_btn_plain" (click)="memberTrialModelShow=false">取消</div>
        </div>
    </div>
</p-dialog>