import { NgModule }from '@angular/core';
import { BrowserModule }  from '@angular/platform-browser';
import {RouteReuseStrategy, RouterModule, Routes} from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy,APP_BASE_HREF,LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FormsModule }    from '@angular/forms';
import { Events } from '../provider/eventService';
import { MatDialogModule } from '@angular/material/dialog'; //确认操作弹窗
import { LoadingModule, ANIMATION_TYPES } from 'ngx-loading';
import { CalendarModule } from 'primeng/calendar';
import { ImageCropperModule } from "ngx-img-cropper";
import { QRCodeModule } from 'angular2-qrcode';
import { DialogModule } from 'primeng/dialog'
import { DropdownModule } from 'primeng/dropdown';
import { NgxNeditorModule } from '@notadd/ngx-neditor';
import { MultiSelectModule } from 'primeng/multiselect';

// 常态数据
import {Department,PlatformApply} from '../normalData/index';
// 服务
import { Api,DataApi,Config,Local,Opera,FormatDate,Eventemit,MyMMessage,SimpleReuseStrategy} from '../provider/index';
import { AppRoutingModule }   from './app-routes';
// 管道
import { NameImg,TextFormat,TextLight,CodeTransform,CodeMoreTransform,NoticeTransform,SalaryFilter,TimeTransform,OnlineTime,NumTransform,AgeTransform,HeightTransform,ConTimeTransform,SafeHtmlPipe,clearSpace,ExcludeEducationPipe } from '../pipe/index';
 //组件部分
import { AppComponent }   from './app.component';
import { LoginComponent }  from './login/login.component';
import { HomeComponent }  from './home/home.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { GuideComponent } from './guide/guide.component';
import { IdentityComponent } from './identity/identity.component';
import { IdentityStatusComponent } from './identity-status/identity-status.component';
import { HomepageComponent } from './homepage/homepage.component';
import { CollegeResumeComponent } from './college-resume/college-resume.component';
import { PagingComponent } from './paging/paging.component';
import { CollegeCampustalkComponent } from './college-campustalk/college-campustalk.component';
import { CollegeMilkroundComponent } from './college-milkround/college-milkround.component';
import { ChatContactComponent } from './chat-contact/chat-contact.component';
import { ChatCollectionComponent } from './chat-collection/chat-collection.component';
import { ChatLookComponent } from './chat-look/chat-look.component';
import { CollegeResumedetailsComponent } from './college-resumedetails/college-resumedetails.component';
import { CollegeDetailsComponent } from './college-details/college-details.component';
import { ModelCollegeIdentityComponent } from './model-college-identity/model-college-identity.component';
import { CollegeCampusSignComponent } from './college-campus-sign/college-campus-sign.component';
import { ModelStillMoreComponent } from './model-still-more/model-still-more.component';
import { CollegeMilkdetailsComponent } from './college-milkdetails/college-milkdetails.component';
import { ModelCollegeMilksignComponent } from './model-college-milksign/model-college-milksign.component';
import { CompanyIdentityComponent } from './company-identity/company-identity.component';
import { ModelCompanyIdentityComponent } from './model-company-identity/model-company-identity.component';
import { PositionResumeComponent } from './position-resume/position-resume.component';
import { ModelDeliveryResumeComponent } from './model-delivery-resume/model-delivery-resume.component';
import { CollectionResumeComponent } from './collection-resume/collection-resume.component';
import { ModelCollectionResumeComponent } from './model-collection-resume/model-collection-resume.component';
import { PositionNoticeComponent } from './position-notice/position-notice.component';
import { PositionHiredComponent } from './position-hired/position-hired.component';
import { PositionCampusComponent } from './position-campus/position-campus.component';
import { PositionMilkComponent } from './position-milk/position-milk.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { CompanyAccountComponent } from './company-account/company-account.component';
import { ModelSelectMoreComponent } from './model-select-more/model-select-more.component';
import { ModelSelectTwoComponent } from './model-select-two/model-select-two.component';
import { ModelPositionTemplateComponent } from './model-position-template/model-position-template.component';
import { ModelHiredDepartmentComponent } from './model-hired-department/model-hired-department.component';
import { ModelHiredTalentComponent } from './model-hired-talent/model-hired-talent.component';
import { ModelAddressAddComponent } from './model-address-add/model-address-add.component';
import { CompanyDetailsAddressComponent } from './company-details-address/company-details-address.component';
import { ModelCompanyLabelComponent } from './model-company-label/model-company-label.component';
import { ModelCompanyProductComponent } from './model-company-product/model-company-product.component';
import { ModelCompanyCoursesComponent } from './model-company-courses/model-company-courses.component';
import { ModelCompanyPhoneComponent } from './model-company-phone/model-company-phone.component';
import { ModelChatCommonComponent } from './model-chat-common/model-chat-common.component';
import { ModelChatPositionComponent } from './model-chat-position/model-chat-position.component';
import { ModelChatPositionFirstComponent } from './model-chat-position-first/model-chat-position-first.component';
import { ModelPositionHiredComponent } from './model-position-hired/model-position-hired.component';
import { ModelPositionNoticeComponent } from './model-position-notice/model-position-notice.component';
import { CollegeMilkSpaceComponent } from './college-milk-space/college-milk-space.component';
import { CollegeMilkAirSpaceComponent } from './college-milk-air-space/college-milk-air-space.component';
import { CollegeDetailsOrgComponent } from './college-details-org/college-details-org.component';
import { CollegeDetailsMajorComponent } from './college-details-major/college-details-major.component';
import { CollegeDetailsMilkComponent } from './college-details-milk/college-details-milk.component';
import { CollegeDetailsCampusComponent } from './college-details-campus/college-details-campus.component';
import { CollegeDetailsNewsComponent } from './college-details-news/college-details-news.component';
import { CollegeDetailsNewstxtComponent } from './college-details-newstxt/college-details-newstxt.component';
import { CollegeDetailsStudentComponent } from './college-details-student/college-details-student.component';
import { ModelChatReportComponent } from './model-chat-report/model-chat-report.component';
import { PositionManageComponent } from './position-manage/position-manage.component';
import { PositionPublishComponent } from './position-publish/position-publish.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ModelPositionMoreComponent } from './model-position-more/model-position-more.component';
import { CompanyIdentityDetailsComponent } from './company-identity-details/company-identity-details.component';
import { CollegeMilkSignComponent } from './college-milk-sign/college-milk-sign.component';
import { ModelStillMilkComponent } from './model-still-milk/model-still-milk.component';
import { GuideCompanyComponent } from './guide-company/guide-company.component';
import { GuideAuditComponent } from './guide-audit/guide-audit.component';
import { ModelCompanyStrengthComponent } from './model-company-strength/model-company-strength.component';
import { ModelCropperComponent } from './model-cropper/model-cropper.component';
import { PositionRulesComponent } from './position-rules/position-rules.component';
import { PositionRulesDetailsComponent } from './position-rules-details/position-rules-details.component';
import { PositionRulesEditorComponent } from './position-rules-editor/position-rules-editor.component';
import { CompanyAuthenticationComponent } from './company-authentication/company-authentication.component';
import { ModelAuthExplainComponent } from './model-auth-explain/model-auth-explain.component';
import { ModelAuthAgreenmentComponent } from './model-auth-agreenment/model-auth-agreenment.component';
import { CompanyPayComponent } from './company-pay/company-pay.component';
import { CompanyStatusOneComponent } from './company-status-one/company-status-one.component';
import { CompanyStatusTwoComponent } from './company-status-two/company-status-two.component';
import { ModelPayWechatComponent } from './model-pay-wechat/model-pay-wechat.component';
import { CompanyStatusThreeComponent } from './company-status-three/company-status-three.component';
import { CompanyStatusFourComponent } from './company-status-four/company-status-four.component';
import { CollegeDetailsPreachComponent } from './college-details-preach/college-details-preach.component';
import { ConfirmsComponent } from './confirms/confirms.component';
import { ModelCompanyNameComponent } from './model-company-name/model-company-name.component';
import { CompanyReceiptComponent } from './company-receipt/company-receipt.component';
import { CompanyRecordComponent } from './company-record/company-record.component';
import { CompanyReceiptSendComponent } from './company-receipt-send/company-receipt-send.component';
import { CloudtipComponent } from './cloudtip/cloudtip.component';
import { CollegeAuthenticationWaitComponent } from './college-authentication-wait/college-authentication-wait.component';
import { CollegeAuthenticationSucComponent } from './college-authentication-suc/college-authentication-suc.component';
import { ModelCollectionApproComponent } from './model-collection-appro/model-collection-appro.component';
import { ModelCollectionInterviewComponent } from './model-collection-interview/model-collection-interview.component';
import { CompanyReceiptSucComponent } from './company-receipt-suc/company-receipt-suc.component';
//import { MemberCenterComponent } from './member-center/member-center.component';
import { MemberProductDetailComponent } from './member-product-detail/member-product-detail.component';
import { MemberProductListComponent } from './member-product-list/member-product-list.component';
import { ModelSendMsgComponent } from './model-send-msg/model-send-msg.component';
import { FindPswComponent } from './find-psw/find-psw.component';
import { BatchInvitationComponent } from './batch-invitation/batch-invitation.component';
import { WorkSpaseComponent } from './work-spase/work-spase.component';
import { HotValueComponent } from './hotvalue-rank/hotvalue-rank.component';
import { NotificationCenterComponent } from './notification-center/notification-center.component';
import { NotificationPendingComponent } from './notification-pending/notification-pending.component';
import { CampusToolComponent } from './campus-tool/campus-tool.component';
import { MyToolComponent } from './my-tool/my-tool.component';
import { BuyLaCoinComponent } from './buy-la-coin/buy-la-coin.component';
import { BuyPayProductComponent } from './buy-pay-product/buy-pay-product.component';
import { MyCardComponent } from './my-card/my-card.component';
import { PayHistoryComponent } from './pay-history/pay-history.component';
import { PayInvoiceComponent } from './pay-invoice/pay-invoice.component';
import { ProductUseHistoryComponent } from './product-use-history/product-use-history.component';
import { BuyPayProductExtendComponent } from './buy-pay-product-extend/buy-pay-product-extend.component';
import { MySingleProductListComponent } from './my-single-product-list/my-single-product-list.component';
import { ActivityInvitationComponent } from './activity-invitation/activity-invitation.component';
import { PositionPushsComponent } from './position-pushs/position-pushs.component';
import { BuyExplainComponent } from './buy-explain/buy-explain.component';
import {SelectCheckboxComponent} from "./select-checkbox/select-checkbox.component";
import { SearchPeopleComponent } from './search-people/search-people.component';
import { ChatSingleComponent } from './chat-single/chat-single.component';
import {scrollTool} from "../provider/scrollTool";
import { AccountLogoutComponent } from './account-logout/account-logout.component';
import { NavigationRouteComponent } from './navigation-route/navigation-route.component';
import { AccountLogoutHistoryComponent } from './account-logout-history/account-logout-history.component';
import {regexManager} from "../provider/regexManager";
import {productManager} from "../provider/productManager";
import {activityManager} from "../provider/activityManager";
import { VoiceCallRecordComponent } from './voice-call-record/voice-call-record.component';
import { PositionPushRecordComponent } from './position-push-record/position-push-record.component';
import { ActivityInviteRecordComponent } from './activity-invite-record/activity-invite-record.component';
import { VoiceCallModelComponent } from './voice-call-model/voice-call-model.component';
import { ResumeLabelModelComponent } from './resume-label-model/resume-label-model.component';
import { PositionPushModelComponent } from './position-push-model/position-push-model.component';
import { ActivityInviteRecordDetailComponent } from './activity-invite-record-detail/activity-invite-record-detail.component';
import { CountUpModule} from 'countup.js-angular2' ;
import { SmailPagingComponent } from './smail-paging/smail-paging.component';
import { NoneVipAlertComponent } from './none-vip-alert/none-vip-alert.component';
import { ZengzhixieyiPageComponent } from './zengzhixieyi-page/zengzhixieyi-page.component';
import { AirCampusDetailComponent } from './air-campus-detail/air-campus-detail.component';
import { CreatAirCampusComponent } from './creat-air-campus/creat-air-campus.component';
import { AirCampHistoryComponent } from './air-camp-history/air-camp-history.component';
import { AirCampHistoryDetailComponent } from './air-camp-history-detail/air-camp-history-detail.component';
import { CheckMemberModelComponent } from './check-member-model/check-member-model.component';
import { DepartmentSelfAlertComponent } from './department-self-alert/department-self-alert.component';
import { LogoutAccountModelComponent } from './logout-account-model/logout-account-model.component';
import { AirCampustalkReordComponent } from './air-campustalk-reord/air-campustalk-reord.component';
import { HttpErrModelComponent } from './http-err-model/http-err-model.component';
import { PaymentOrderListComponent } from './payment-order-list/payment-order-list.component';
import { ModelPayCodeComponent } from './model-pay-code/model-pay-code.component';
import { PayInvoiceModelComponent } from './pay-invoice-model/pay-invoice-model.component';
import { GuWenCardViewComponent } from './gu-wen-card-view/gu-wen-card-view.component';
import { PayChipsModelComponent } from './pay-chips-model/pay-chips-model.component';
import { UserProtocolModelComponent } from './user-protocol-model/user-protocol-model.component';

//declarations —— 该应用所拥有的组件。
//imports —— 导入 BrowserModule 以获取浏览器特有的服务，比如 DOM 渲染、无害化处理和位置（locat;
import { AdvertListPageComponent } from './advert-list-page/advert-list-page.component';
import { CollectFormComponent } from './collect-form/collect-form.component';
import { AirCampListComponent } from './air-camp-list/air-camp-list.component';
import { AirLiveExplainComponent } from './air-live-explain/air-live-explain.component';
//prov;
//bootstrap —— 根组件�;�Angular 创建它并插入 index.html 宿主页面
import { CollegeMilkDayComponent }  from './college-milk-day/college-milk-day.component';
import { FindAccountComponent } from './find-account/find-account.component';
import {CarouselModule} from 'primeng/carousel';
import { InterviewComponent } from './interview/interview.component';
import { RightsComponent } from './rights/rights.component';
import { PushCollegeModelComponent } from './push-college-model/push-college-model.component';
import { ProductModelComponent } from './product-model/product-model.component';
import { AuthenticationComponent } from './authentication/authentication.component';

@NgModule({
    imports: [
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ImageCropperModule,
        NgxNeditorModule,
        DialogModule,
        MatDialogModule,
        QRCodeModule,
        DialogModule,
        DropdownModule,
        CountUpModule,
        CarouselModule,
        LoadingModule.forRoot({
            animationType: ANIMATION_TYPES.circleSwish,
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '4px',
            primaryColour: '#5e4fff',
            secondaryColour: '#5e4fff',
            tertiaryColour: '#5e4fff'
        }),
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
        }),
        HttpClientModule,
        CalendarModule,
        MultiSelectModule,
    ],
    declarations: [
        NameImg,
        TextFormat,
        clearSpace,
        TextLight,
        CodeTransform,
        CodeMoreTransform,
        ConTimeTransform,
        NoticeTransform,
        SalaryFilter,
        TimeTransform,
        OnlineTime,
        NumTransform,
        AgeTransform,
        SafeHtmlPipe,
        HeightTransform,
        AppComponent,
        HomepageComponent,
        CollegeResumeComponent,
        HomeComponent,
        PagingComponent,
        CollegeCampustalkComponent,
        CollegeMilkroundComponent,
        ChatContactComponent,
        LoginComponent,
        ConfirmComponent,
        GuideComponent,
        CollegeDetailsComponent,
        ModelCollegeIdentityComponent,
        CollegeCampusSignComponent,
        ModelStillMoreComponent,
        CollegeMilkdetailsComponent,
        ModelCollegeMilksignComponent,
        ModelCompanyIdentityComponent,
        PositionResumeComponent,
        ModelDeliveryResumeComponent,
        CollectionResumeComponent,
        ModelCollectionResumeComponent,
        PositionNoticeComponent,
        PositionHiredComponent,
        PositionCampusComponent,
        PositionMilkComponent,
        CompanyDetailsComponent,
        CompanyAccountComponent,
        ModelSelectMoreComponent,
        ModelSelectTwoComponent,
        ModelPositionTemplateComponent,
        ModelHiredDepartmentComponent,
        ModelHiredTalentComponent,
        ModelAddressAddComponent,
        CompanyDetailsAddressComponent,
        ModelCompanyLabelComponent,
        ModelCompanyProductComponent,
        ModelCompanyCoursesComponent,
        ModelCompanyPhoneComponent,
        ModelChatCommonComponent,
        ModelChatPositionComponent,
        ModelChatPositionFirstComponent,
        ModelPositionHiredComponent,
        ModelPositionNoticeComponent,
        CollegeMilkSpaceComponent,
        CollegeMilkAirSpaceComponent,
        CollegeDetailsOrgComponent,
        CollegeDetailsMajorComponent,
        CollegeDetailsMilkComponent,
        CollegeDetailsCampusComponent,
        CollegeDetailsNewsComponent,
        CollegeDetailsNewstxtComponent,
        CollegeDetailsStudentComponent,
        ModelChatReportComponent,
        PositionManageComponent,
        PositionPublishComponent,
        NotFoundComponent,
        ModelPositionMoreComponent,
        CompanyIdentityDetailsComponent,
        CollegeMilkSignComponent,
        ModelStillMilkComponent,
        GuideCompanyComponent,
        GuideAuditComponent,
        ModelCompanyStrengthComponent,
        ModelCropperComponent,
        PositionRulesComponent,
        PositionRulesDetailsComponent,
        PositionRulesEditorComponent,
        CompanyAuthenticationComponent,
        ModelAuthExplainComponent,
        ModelAuthAgreenmentComponent,
        CompanyPayComponent,
        CompanyStatusOneComponent,
        CompanyStatusTwoComponent,
        ModelPayWechatComponent,
        CompanyStatusThreeComponent,
        CompanyIdentityComponent,
        CompanyStatusFourComponent,
        CollegeDetailsPreachComponent,
        ConfirmsComponent,
        ModelCompanyNameComponent,
        CompanyReceiptComponent,
        CompanyRecordComponent,
        CompanyReceiptSendComponent,
        CloudtipComponent,
        CollegeAuthenticationWaitComponent,
        CollegeAuthenticationSucComponent,
        ModelCollectionApproComponent,
        ModelCollectionInterviewComponent,
        CompanyReceiptSucComponent,
        MemberProductDetailComponent,
        MemberProductListComponent,
        ModelSendMsgComponent,
        BatchInvitationComponent,
        WorkSpaseComponent,
        HotValueComponent,
        NotificationCenterComponent,
        NotificationPendingComponent,
        CampusToolComponent,
        MyToolComponent,
        BuyLaCoinComponent,
        BuyPayProductComponent,
        MyCardComponent,
        PayHistoryComponent,
        PayInvoiceComponent,
        ProductUseHistoryComponent,
        BuyPayProductExtendComponent,
        MySingleProductListComponent,
        ActivityInvitationComponent,
        PositionPushsComponent,
        BuyExplainComponent,
        IdentityComponent,
        SearchPeopleComponent,
        ChatSingleComponent,
        AccountLogoutComponent,
        NavigationRouteComponent,
        AccountLogoutHistoryComponent,
        VoiceCallRecordComponent,
        PositionPushRecordComponent,
        ActivityInviteRecordComponent,
        VoiceCallModelComponent,
        ResumeLabelModelComponent,
        PositionPushModelComponent,
        ActivityInviteRecordDetailComponent,
        SmailPagingComponent,
        NoneVipAlertComponent,
        ZengzhixieyiPageComponent,
        AirCampusDetailComponent,
        CreatAirCampusComponent,
        AirCampHistoryComponent,
        AirCampHistoryDetailComponent,
        CheckMemberModelComponent,
        DepartmentSelfAlertComponent,
        LogoutAccountModelComponent,
        AirCampustalkReordComponent,
        HttpErrModelComponent,
        PaymentOrderListComponent,
        ModelPayCodeComponent,
        PayInvoiceModelComponent,
        GuWenCardViewComponent,
        PayChipsModelComponent,
        UserProtocolModelComponent,
        IdentityStatusComponent,
        AdvertListPageComponent,
        CollectFormComponent,
        AirCampListComponent,
        AirLiveExplainComponent,
        CollegeMilkDayComponent,
        FindAccountComponent,
        InterviewComponent,
        ChatCollectionComponent,
        ChatLookComponent,
        CollegeResumedetailsComponent,
        FindPswComponent,
        SelectCheckboxComponent,
        RightsComponent,
        PushCollegeModelComponent,
        ProductModelComponent,
        AuthenticationComponent,
        ExcludeEducationPipe
    ],
    providers: [
        Department,
        PlatformApply,
        Events,
        Config,
        Api,
        Local,
        DataApi,
        Opera,
        FormatDate,
        MyMMessage,
        Eventemit,
        scrollTool,
        regexManager,
        productManager,
        activityManager,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: APP_BASE_HREF, useValue: '/' },
        /*路由复用策略*/
        { provide: RouteReuseStrategy, useClass: SimpleReuseStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
