import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from  '@angular/router';
import {Config, DataApi} from "../../provider";

@Component({
  selector: 'app-find-psw',
  templateUrl: './find-psw.component.html',
  styleUrls: ['./find-psw.component.css']
})
export class FindPswComponent implements OnInit {

  public tap=1;   //当前步骤
  public findPswModel={
    email:'',
    emailCode:'',
    oldpsw:'',
    newpsw:''
  };
  public vcodeImg ='';//图片验证码（用户填写）
  public imgCode:any;//图形验证码
  public imgCodeId:any;//图形验证码id
  public isGetCode= true;//是否可以获取验证码
  public sengCodeBtn= '获取邮箱验证码';//是否可以获取验证码

  public config: Config;
  public password_strength=0;
  public password_strength_message='';

  constructor(
    public toastr: ToastrService,
    private router: Router,
    public dataApi: DataApi,
  ) {
    this.config = Config.getInstance();//实例化config

    window.onpopstate = (event) => {
      console.log('onpopstate',event);
      window.location.reload()
    };
  }
  ngOnInit() {
    this.config.isNavActive = 'login';
    this.getCode();
  }

  /**
   * 获取邮箱验证码
   */
  getEmailCode(){
    if(this.findPswModel.email=='') {
      this.toastr.error('请填写邮箱');
      return;
    }
    if(this.vcodeImg=='') {
      this.toastr.error('请填写图片验证码');
      return;
    }
    if(this.sengCodeBtn !='获取邮箱验证码') {
      this.toastr.error('60秒内不能重复发送');
      return;
    }

    let data={
      email:this.findPswModel.email,
      vcode:{
        vcodeid:this.imgCodeId,
        vcode_text:this.vcodeImg,
      },
      account_type:'employer',
    };
    let phoneModel={
      format_type:2,//0:手机号，1：手机号&固话；2.邮箱
      data:this.findPswModel.email
    }
    this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
      if(res.value){
        this.dataApi.findPswSendEmail(data).then((res:any)=>{
          console.log("发送邮箱",res,data);
          this.toastr.success('获取成功');

          let count = 60;
          this.sengCodeBtn = count + 's';
          let timer = setInterval(()=>{
            count -= 1;
            this.sengCodeBtn = count + 's';
            if (count === 0) {
              clearInterval(timer);
              this.sengCodeBtn = '获取邮箱验证码';
            }
          }, 1000);
        }).catch((err)=>{
          console.log("发送邮箱失败",err);
          this.toastr.error(err);return;
        })

      } else {
        this.toastr.warning('邮箱格式错误');

      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error('工作邮箱格式错误');
      return;
    })
  }

  /**
   * 下一步上一步
   * @param tab
   */
  nextTap(tab){
    if(tab==2) {
      if(this.findPswModel.email==''||this.findPswModel.emailCode==''||this.vcodeImg=='') {
        this.toastr.error('信息填写完整');
        return;
      }
      let data={
        email:this.findPswModel.email,
        vcode_text:this.findPswModel.emailCode,
      };
      this.dataApi.findPswCheckEmailCode(data).then((res:any)=>{
        this.toastr.success('验证成功')
        this.tap=tab;
      }).catch((err)=>{
        console.log("验证失败",err);
        this.toastr.error(err);return;
      })
    } else {
      this.tap=tab;
    }
  }

  /**
   * 完成按钮
   */
  finishClick(){
    console.log("this.findPswModel",this.findPswModel);
    if(this.findPswModel.email==''|| this.findPswModel.emailCode=='') {
      this.toastr.error('请重新验证邮箱');
      this.tap=1;
      return;
    }
    if(this.findPswModel.newpsw==''||this.findPswModel.oldpsw=='') {
      this.toastr.error('信息填写完整');
      return;
    }
    if(this.password_strength<3) {
      this.toastr.error('新密码强度低');return;
    }
    if(this.findPswModel.newpsw!=this.findPswModel.oldpsw) {
      this.toastr.error('两次密码不一致');
      return;
    }
      let data = {
        "email": this.findPswModel.email,
        "reset_token":  this.findPswModel.emailCode,
        "newpassword": this.findPswModel.newpsw,
      }
      this.dataApi.findPswResetPsw(data).then((res:any)=>{
        console.log("修改密码",res,data);
        this.toastr.success('修改成功')
        setTimeout(() => {
          this.router.navigateByUrl('/login');
        },800);
      }).catch((err)=>{
        console.log("修改失败",err);
        this.toastr.error(err);return;
      })
  }


  cancel(){
    this.router.navigateByUrl('/login');

  }
  // 获取图形验证码
  getCode(){
    this.dataApi.getImgCode().then((res:any)=>{
      this.imgCode='data:image/png;base64,'+res.vcode_image;//验证码图片base64
      this.imgCodeId=res.vcodeid;
    }).catch((err)=>{
    })
  }
  gotoFindAccount(){
    this.router.navigateByUrl('/find-account');
  }
  /** 密码失焦 */
  passwordBlur(e) {
    let password = e.target.value;
    if (!password){ 
      this.password_strength_message = '';
      this.password_strength = 0
      return 
    };
    this.dataApi.checkSafetyPassword(password).then((res: any) => {
      this.password_strength = res.score + 1;
      this.password_strength_message = ['','密码强度极低，极易被破解','密码强度低，可不太安全','密码强度中等，存在一定风险','密码强度高，安全性较好','密码强度极高，安全性强'][this.password_strength]
      if(!this.password_strength){
        this.password_strength_message = res.message
      }
    });
  }
}
