<!-- 页头部分 -->
<br>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <router-outlet></router-outlet>
    <app-model-company-phone #phone [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-company-phone>
    <app-model-cropper #crop [isShow]="modelTwoShow" (onVoted)="ModelTwodismiss($event)"></app-model-cropper>
    <!-- v3.0 -->
    <div class="secondNav">
        <div class="leftNav">
            <div class="item" (click)="goComHome()">公司管理</div>
            <div class="item active">账号管理<span></span></div>
        </div>
        <div class="cancellation" (click)="accountLogout()">注销账号</div>
    </div>

    <div class="account" *ngIf="myUser">
        <div class="main">
            <!--左侧头像-->
            <div class="portrait">
                <div class="title">上传头像</div>
                <div class="box">
                    <label class="uploadImg-label department-logo" for="uploadUserAvatar">
                        <img class="avatar" *ngIf="myUser.avatar" [src]="myUser.avatar">
                        <img class="pushImg" *ngIf="!myUser.avatar" src="/assets/images/v3/pushposition.png">
                    </label>
                    <input class="uploadImg" id="uploadUserAvatar" (change)="previewPic($event)" type="file" accept="image/jpeg,image/jpg,image/png">
                </div>
                <p>点击上传用户头像</p>
                <p class="active">像素：200*200px,格式：jpg,png</p>
            </div>
            <!--右侧头像-->
            <div class="rightBox">
                <div class="formContainer">
                    <!-- 姓名 -->
                    <div class="module">
                        <div class="_left">姓名<span>（必填）</span></div>
                        <div class="_right">
                            <div class="val">
                                <div *ngIf="isName" class="disabled-text">{{userData.name}}</div>
                                <input *ngIf="!isName" type="text" [(ngModel)]="userData.name" placeholder="请填写姓名">
                            </div>
                            <!-- <div class="requiredText">一经认证姓名不可修改</div> -->
                        </div>
                    </div>
                    <!-- 性别 -->
                    <div class="module">
                        <div class="_left">性别<span>（必填）</span></div>
                        <div class="_right">
                            <div class="val">
                                <select class="activeBg" autocomplete="off" [(ngModel)]="userData.gender">
                                    <option class="place-text" value='' disabled hidden selected="selected">选择性别</option>
                                    <option value="01">男</option>
                                    <option value="02">女</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!-- 职务 -->
                    <div class="module">
                        <div class="_left">职务<span>（必填）</span></div>
                        <div class="_right">
                            <div class="val">
                                <input type="text" [(ngModel)]="userData.post" placeholder="请填写职务">
                            </div>
                        </div>
                    </div>
                    <!-- 工作邮箱 -->
                    <div class="module">
                        <div class="_left">工作邮箱<span>（必填）</span></div>
                        <div class="_right">
                            <div class="val">
                                <input type="text" [(ngModel)]="userData.email" placeholder="请填写工作邮箱">
                            </div>
                        </div>
                        <div class="rightBtn">
                            <div class="item" (click)="setUserInfoOpen('email',true)" [ngClass]="{'openInfo':contact_info_switch.is_public_email,'closeInfo':!contact_info_switch.is_public_email}">
                                <div class="choice">
                                    <span></span>
                                </div>
                                公开
                            </div>
                            <div class="item" (click)="setUserInfoOpen('email',false)" [ngClass]="{'openInfo':!contact_info_switch.is_public_email,'closeInfo':contact_info_switch.is_public_email}">
                                <div class="choice">
                                    <span></span>
                                </div>
                                不公开
                            </div>
                        </div>
                    </div>
                    <!-- 微信号 -->
                    <div class="module">
                        <div class="_left">微信号<span>（必填）</span></div>
                        <div class="_right">
                            <div class="val">
                                <input type="text" [(ngModel)]="wechat" placeholder="请填写微信号">
                            </div>
                        </div>
                        <div class="rightBtn">
                            <div class="item" (click)="setUserInfoOpen('wx',true)" [ngClass]="{'openInfo':contact_info_switch.is_public_wx,'closeInfo':!contact_info_switch.is_public_wx}">
                                <div class="choice">
                                    <span></span>
                                </div>
                                公开
                            </div>
                            <div class="item" [ngClass]="{'openInfo':!contact_info_switch.is_public_wx,'closeInfo':contact_info_switch.is_public_wx}" (click)="setUserInfoOpen('wx',false)">
                                <div class="choice">
                                    <span></span>
                                </div>
                                不公开
                            </div>
                        </div>
                    </div>
                    <!-- 绑定手机 -->
                    <div class="module active">
                        <div class="_left">绑定手机</div>
                        <div class="_right">
                            <div class="val">
                                <div class="disabled-text">{{loginPhone}}<span class="mobile_actived" *ngIf="!mobile_actived">-未激活</span></div>
                            </div>
                        </div>
                        <div class="rightBtn">
                            <div class="item xiugai" (click)="uploadPhone()"><img src="/assets/images/v3/address-edit.png">修改</div>
                            <div class="item" [ngClass]="{'openInfo':contact_info_switch.is_public_mobile,'closeInfo':!contact_info_switch.is_public_mobile}" (click)="setUserInfoOpen('phone',true)">
                                <div class="choice">
                                    <span></span>
                                </div>
                                公开
                            </div>
                            <div class="item" [ngClass]="{'openInfo':!contact_info_switch.is_public_mobile,'closeInfo':contact_info_switch.is_public_mobile}" (click)="setUserInfoOpen('phone',false)">
                                <div class="choice">
                                    <span></span>
                                </div>
                                不公开
                            </div>
                        </div>
                    </div>
                    <!--已绑定登录邮箱-->
                    <div class="module" *ngIf="userData.login_email&&userData.email_actived">
                        <div class="_left">登录邮箱</div>
                        <div class="_right">
                            <div class="val">
                                <!-- <input readonly type="text" [(ngModel)]="userData.login_email" placeholder=""> -->
                                <div class="disabled-text">{{userData.login_email}}<span class="mobile_actived" *ngIf="!userData.email_actived">-未激活</span></div>
                            </div>
                        </div>
                        <div class="rightBtn" style="right: -34px;">
                            <div class="item xiugai" (click)="updateEmail()"><img src="/assets/images/v3/address-edit.png">修改邮箱</div>
                            <div class="item xiugai" (click)="updatePsw()"><img src="/assets/images/v3/password.png">修改密码</div>
                        </div>
                    </div>
                    <!--登录邮箱 -->
                    <div class="module" *ngIf="userData.login_email==''||!userData.email_actived">
                        <div class="_left">绑定登录邮箱</div>
                        <div class="_right">
                            <div class="bindingBtn" (click)="showBingLoginEmailModelClick()"><img src="/assets/images/v3/emIcon.png">绑定登录邮箱</div>
                        </div>
                    </div>
                    <!-- 微信 -->
                    <div class="module">
                        <div class="_left">{{IsBindWeiChat?"解绑登录微信":"绑定登录微信"}}</div>
                        <div class="_right">
                            <div class="bindingBtn" (click)="showBindWeiChatClick()"><img src="/assets/images/v3/wxIcon.png">{{IsBindWeiChat?"解绑登录微信":"绑定登录微信"}}</div>
                        </div>
                    </div>
                    <!-- <div class="sign-list" *ngIf="is_trial_member_show">
                        <span>兑换体验码：</span>
                        <button class="updateEmailBtn deleteWeichat" style="border-color: #5e4fff;color: #5e4fff;margin-left: 0" (click)="exchangeCode()">兑换体验码</button>
                    </div> -->
                    <!-- 简历转发邮箱 -->
                    <div class="module" *ngIf="!bindResumeEmail">
                        <div class="_left">绑定简历转发邮箱</div>
                        <div class="_right">
                            <div class="bindingBtn" (click)="showBindResumeEmailModelClick()"><img src="/assets/images/v3/emIcon.png">绑定简历转发邮箱</div>
                        </div>
                    </div>
                    <div class="module" *ngIf="bindResumeEmail">
                        <div class="_left">简历转发邮箱</div>
                        <div class="_right">
                            <div class="val">
                                <div class="disabled-text">{{bindResumeEmail}}</div>
                            </div>
                        </div>
                        <div class="rightBtn" style="right: -18px;">
                            <div class="item xiugai" (click)="showBindResumeEmailModelClick()"><img src="/assets/images/v3/address-edit.png">修改邮箱</div>
                            <i class="custom_switch" [ngClass]="{'active':bindResumeEmailEnable}" style="margin-left: 20px;" data-active="启用" data-inactive="禁用" (click)="changeBindResumeEmailEnable()"></i>
                        </div>
                    </div>
                    <!-- 保存 -->
                    <div class="submit blueStreamer" *ngIf="myUser" (click)="updateUser()">保存</div>
                </div>
            </div>
        </div>
    </div>

</div>

<!--修改邮箱-->
<p-dialog [contentStyle]="{'padding':'0',border:'0','border-radius':'6px'}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}" [modal]="true" [showHeader]="false" [(visible)]="updateLoginEmailModel">
    <div class="dialog_close_btn" style="top: 19px;right: 32px;" (click)="updateLoginEmailModel=false"><img src="/assets/images/rights/contactMeClos.png"></div>
    <div style="width: 621px;height: 370px;background-color: white;overflow: hidden;">
        <div class="model_title">修改邮箱</div>
        <div class="d_f model_cell" style="padding-top: 42px;">
            <div class="model_cell_title">原邮箱号</div>
            <div class="d_f model_cell_val_box">
                <input class="f_1 model_cell_val" readonly type="text" [(ngModel)]="updateEmailData.oldEmail" placeholder="请填写工作邮箱">
            </div>
        </div>
        <div class="d_f model_cell">
            <div class="model_cell_title">新邮箱号</div>
            <div class="d_f model_cell_val_box">
                <input class="f_1 model_cell_val" type="text" [(ngModel)]="updateEmailData.newEmail" placeholder="请填写新邮箱">
            </div>
        </div>
        <div class="d_f model_cell">
            <div class="model_cell_title">验证码</div>
            <div class="d_f model_cell_val_box">
                <input class="f_1 model_cell_val" type="text" [(ngModel)]="updateEmailData.newEmailCode" placeholder="请填写验证码">
                <div class="custom_btn_plain" (click)="sendEmail(2)">{{sendEmailText2}}</div>
            </div>
        </div>
        <div class="model_explain">修改完成后，请使用新邮箱登录工作啦。</div>
        <div class="custom_btn_primary" (click)="saveEmail()">确定</div>
    </div>
</p-dialog>
<!--修改密码弹窗-->
<p-dialog [contentStyle]="{'padding':'0',border:'0','border-radius':'6px'}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}" [modal]="true" [showHeader]="false" [(visible)]="updateLoginEmailPsw">
    <div class="dialog_close_btn" style="top: 19px;right: 32px;" (click)="updateLoginEmailPsw=false"><img src="/assets/images/rights/contactMeClos.png"></div>
    <div style="width: 621px;padding-bottom: 24px;;background-color: white;overflow: hidden;">
        <div class="model_title">修改密码</div>
        <div class="model_title_explain" *ngIf="updatePswData.type=='email'">系统会将验证码发送到您的登录邮箱：{{bindEmailModel.email}}</div>
        <div class="d_f model_cell" style="padding-top: 42px;" *ngIf="updatePswData.type=='email'">
            <div class="model_cell_title">邮箱</div>
            <div class="d_f model_cell_val_box">
                <input class="f_1 model_cell_val" type="text" [(ngModel)]="bindEmailModel.email" disabled="disabled">
            </div>
        </div>
        <div class="d_f model_cell" *ngIf="updatePswData.type=='email'">
            <div class="model_cell_title">邮箱验证码</div>
            <div class="d_f model_cell_val_box">
                <input class="f_1 model_cell_val" [(ngModel)]="updatePswData.emailCode" placeholder="请填写邮箱验证码">
                <div class="custom_btn_plain" (click)="getBingEmailCode_reset()">{{sendEmailText2}}</div>
            </div>
        </div>
        <div class="d_f model_cell" style="padding-top: 42px;" *ngIf="updatePswData.type=='psw'">
            <div class="model_cell_title">原密码</div>
            <div class="d_f model_cell_val_box">
                <input class="f_1 model_cell_val" style="padding-right: 47px;" type={{seePswType}} [(ngModel)]="updatePswData.oldPsw" placeholder="请填写原密码">
                <img class="c_p model_cell_val_icon" *ngIf="seePswType=='text'" (click)="changeSeePsw(1,'password')" src="assets/images/v3/eye.png">
                <img class="c_p model_cell_val_icon" *ngIf="seePswType=='password'" (click)="changeSeePsw(1,'text')" src="assets/images/v3/eye2.png">
            </div>
        </div>
        <div class="d_f model_cell">
            <div class="model_cell_title">新密码</div>
            <div class="model_cell_val_box">
                <div class="d_f model_cell_val_box">
                    <input class="f_1 model_cell_val" style="padding-right: 47px;" type={{seePswType2}} [(ngModel)]="updatePswData.newPsw" placeholder="请填写新密码" (blur)="passwordBlur($event)">
                    <img class="c_p model_cell_val_icon" *ngIf="seePswType2=='text'" (click)="changeSeePsw(2,'password')" src="assets/images/v3/eye.png">
                    <img class="c_p model_cell_val_icon" *ngIf="seePswType2=='password'" (click)="changeSeePsw(2,'text')" src="assets/images/v3/eye2.png">
                </div>
                <div class="d_f fw_w" [class]="'strength_box'+password_strength"><div [class]="'password_strength'+password_strength"></div><div class="strength_message">{{password_strength_message}}</div></div>
            </div>
        </div>
        <div class="d_f model_cell">
            <div class="model_cell_title">确认密码</div>
            <div class="d_f model_cell_val_box">
                <input class="f_1 model_cell_val" style="padding-right: 47px;" type={{seePswType3}} [(ngModel)]="updatePswData.okPsw" placeholder="请填写确认密码">
                <img class="c_p model_cell_val_icon" *ngIf="seePswType3=='text'" (click)="changeSeePsw(3,'password')" src="assets/images/v3/eye.png">
                <img class="c_p model_cell_val_icon" *ngIf="seePswType3=='password'" (click)="changeSeePsw(3,'text')" src="assets/images/v3/eye2.png">
            </div>
        </div>
        <div class="d_f jc_c">
            <div class="custom_btn primary" (click)="savePsw()">保存</div>
            <div class="custom_btn plain" (click)="changePswType()">{{updatePswData.type=='email'?"通过密码修改":"通过邮箱修改"}}</div>
        </div>
    </div>
</p-dialog>

<!--修改密码弹窗-->
<p-dialog [contentStyle]="{'padding':'0',border:'0','border-radius':'6px'}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}" [modal]="true" [showHeader]="false" [(visible)]="showBingLoginEmailModel">
    <div class="dialog_close_btn" style="top: 19px;right: 32px;" (click)="showBingLoginEmailModel=false"><img src="/assets/images/rights/contactMeClos.png"></div>
    <div style="width: 621px;padding-bottom: 24px;;background-color: white;overflow: hidden;">
        <div class="model_title">绑定登录邮箱</div>
        <div class="d_f model_cell" style="padding-top: 42px;">
            <div class="model_cell_title">绑定登录邮箱</div>
            <div class="d_f model_cell_val_box">
                <input class="f_1 model_cell_val" [(ngModel)]="bindEmailModel.email" placeholder="请填写邮箱">
                <div class="custom_btn_plain" (click)="getBingEmailCode()">{{sendEmailText2}}</div>
            </div>
        </div>
        <div class="d_f model_cell">
            <div class="model_cell_title">输入验证码</div>
            <div class="d_f model_cell_val_box">
                <input class="f_1 model_cell_val" [(ngModel)]="bindEmailModel.code" placeholder="请填写邮箱验证码">
            </div>
        </div>
        <div class="d_f model_cell">
            <div class="model_cell_title">密码</div>
            <div class="model_cell_val_box">
                <input class="w_100 model_cell_val" type='password' [(ngModel)]="bindEmailModel.psw" placeholder="请填写密码" (blur)="passwordBlur($event)">
                <div class="d_f fw_w" [class]="'strength_box'+password_strength"><div [class]="'password_strength'+password_strength"></div><div class="strength_message">{{password_strength_message}}</div></div>
            </div>
        </div>
        <div class="d_f model_cell">
            <div class="model_cell_title">确认密码</div>
            <div class="d_f model_cell_val_box">
                <input class="f_1 model_cell_val" type='password' [(ngModel)]="bindEmailModel.psw2" placeholder="请填写确认密码">
            </div>
        </div>
        <div class="custom_btn_primary" (click)="commitBindLoginEmail()">确定</div>
    </div>
</p-dialog>

<p-dialog [closeOnEscape]="false" [showHeader]="false" [dismissableMask]="false" [contentStyle]="{'padding':'0',border:0}" [(visible)]="showDeleteBingWeiChatModel" [style]="{'padding':0,'border':0,'box-shadow':'unset','width':438,'height':260,'minHeight':260}"
    [modal]=true>
    <div class="selteTypeModel">
        <div class="selteTypeModelTitle flex_s"><span>解绑登录微信</span><img (click)="closeBingWeiChat()" src="../../assets/images/close_default.png" /></div>
        <div class="bingWeiChatCell marginTop20 flex_l">
            <div class="wcLeft">绑定手机：</div>
            <div class="wcRight flex_l">
                <img class="wxIcon" src="../../assets/images/bindWXphone.png" />
                <input class="wxInput" disabled [(ngModel)]="deleteWX.phone" />
            </div>
        </div>
        <div class="bingWeiChatCell marginTop24 flex_l">
            <div class="wcLeft">验证码：</div>
            <div class="wcRight flex_l">
                <img class="wxIcon" src="../../assets/images/bindWXcode.png" />
                <input class="wxInput" [(ngModel)]="deleteWX.code" />
                <div class="wxsendSms" (click)="sendSms()">{{countDown}}</div>
            </div>
        </div>
        <div class="bindWeiChatBtn" (click)="deleteWxBing()">解除绑定</div>
    </div>
</p-dialog>

<p-dialog [closeOnEscape]="false" [showHeader]="false" [dismissableMask]="false" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px'}" [(visible)]="showBingWeiChatModel" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}"
    [modal]=true>
    <div class="dialog_close_btn" style="top: 19px;right: 40px;" (click)="showBingWeiChatModel=false"><img src="/assets/images/rights/contactMeClos.png"></div>
    <div style="width: 368px;height: 326px;background-color: white;overflow: hidden;">
        <div class="d_f jc_c selteType_model_head">
            <img class="selteType_model_head_icon" src="../../assets/images/wechat.png" />
            <div>
                <div class="selteType_model_head_title">微信扫码</div>
                <div class="selteType_model_head_explain">使用微信扫码关注公众号即可绑定登录微信</div>
            </div>
        </div>
        <div class="d_f jc_c selteType_model_code_box">
            <img class="selteType_model_code" *ngIf="weChatCode" src={{weChatCode}} style="width: 127px;height: 127px" />
            <img class="selteType_model_rush" *ngIf="checkWeChatIndex==61" (click)="startCheckBingWx()" src="../../assets/images/pay-refresh.png" />
        </div>
    </div>
</p-dialog>

<!-- 产品介绍/请校招顾问联系我 -->
<app-product-model #productModel [isShow]="isproductShowModel" (modelHidden)="productModelHidden($event)"></app-product-model>

<!--领取体验会员-->
<p-dialog [closeOnEscape]="false" [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="showExperienceModel" [modal]=true>
    <div class="experienceModel" [class.active]="isExperence">
        <div class="main01" *ngIf="!isExperence">
            <div class="title">兑换体验码</div>
            <div class="input">
                <input type="text" placeholder="请输入体验码" [(ngModel)]="experienceCode">
            </div>
            <div class="bindWeiChatBtn" (click)="exchangeBtn()">立即兑换</div>
        </div>

        <div class="main02" *ngIf="isExperence">
            <div class="title"><img src="/assets/images/rights/refreshSuccess.png">兑换成功</div>
            <div class="box">
                <div class="logo">
                    <img src="/assets/images/rights/item02.png">体验会员权益
                </div>
                <div class="moel">
                    <div class="lable">发布职位:</div>
                    <div class="val">15个</div>
                </div>
                <div class="moel">
                    <div class="lable">职位刷新:</div>
                    <div class="val">15个</div>
                </div>
                <p>发布职位90天内全网可见</p>
            </div>
            <div class="bindWeiChatBtn closBtn" (click)="closBtn()">确定</div>
        </div>

    </div>
</p-dialog>

<!--领取体验会员-->
<p-dialog [closeOnEscape]="false" [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="experienceModelTip" [modal]=true>
    <div class="experienceModelTip">
        <div class="title">兑换体验码</div>
        <div class="text">您已领取过体验会员，开通会员权益请联系校招顾问</div>
        <div class="btn blueStreamer" (click)="product('','contactMe')">请校招顾问联系我</div>
    </div>
</p-dialog>

<!--绑定简历转发邮箱弹窗-->
<p-dialog [contentStyle]="{'padding':'0',border:'0','border-radius':'6px'}" [dismissableMask]="true" [style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}" [modal]="true" [showHeader]="false" [(visible)]="showBindResumeEmailModel">
    <div class="dialog_close_btn" style="top: 19px;right: 32px;" (click)="showBindResumeEmailModel=false"><img src="/assets/images/rights/contactMeClos.png"></div>
    <div style="width: 621px;padding-bottom: 24px;;background-color: white;overflow: hidden;">
        <div class="model_title" *ngIf="bindResumeEmail">修改简历转发邮箱</div>
        <div class="model_title" *ngIf="!bindResumeEmail">绑定简历转发邮箱</div>
        <div style="padding-top: 20px;"></div>
        <div class="d_f model_cell" *ngIf="bindResumeEmail">
            <div class="model_cell_title">原简历转发邮箱</div>
            <div class="d_f model_cell_val_box">
                <input class="f_1 model_cell_val" readonly type="text" [ngModel]="bindResumeEmail" placeholder="请填写工作邮箱">
            </div>
        </div>
        <div class="d_f model_cell">
            <div class="model_cell_title">绑定简历转发邮箱</div>
            <div class="d_f model_cell_val_box">
                <input class="f_1 model_cell_val" [(ngModel)]="bindResumeEmailModel.emailAddress" placeholder="请填写邮箱">
                <div class="custom_btn_plain" (click)="getBindResumeEmailCode()">{{sendEmailText3}}</div>
            </div>
        </div>
        <div class="d_f model_cell">
            <div class="model_cell_title">输入验证码</div>
            <div class="d_f model_cell_val_box">
                <input class="f_1 model_cell_val" [(ngModel)]="bindResumeEmailModel.activeCode" placeholder="请填写邮箱验证码">
            </div>
        </div>
        <div class="custom_btn_primary" (click)="commitBindResumeEmail()">确定</div>
    </div>
</p-dialog>