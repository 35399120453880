import { Component, OnInit, Input, Output, ViewChild, EventEmitter, enableProdMode } from '@angular/core';
import { DataApi, Local, Opera, Constants, Config } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmComponent } from '../confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { ElementRef } from '@angular/core';

import { ModelPositionNoticeComponent } from '../model-position-notice/model-position-notice.component';
import { scrollTool } from "../../provider/scrollTool";
import { ResumeLabelModelComponent } from "../resume-label-model/resume-label-model.component";
import { Events } from '../../provider/eventService';
import { NoneVipAlertComponent } from "../none-vip-alert/none-vip-alert.component";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
import { PlatformApply } from '../../normalData/platformApply'
declare var layui;
declare let window;
@Component({
  selector: 'app-model-delivery-resume',
  templateUrl: './model-delivery-resume.component.html',
  styleUrls: ['./model-delivery-resume.component.css']
})
export class ModelDeliveryResumeComponent implements OnInit {
  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true })
  productModel: ProductModelComponent;

  @ViewChild('noticeDelivery') child: ModelPositionNoticeComponent;
  @ViewChild('resumeLabelModel') resumeLabelModel: ResumeLabelModelComponent;

  public isShowMemberMoudel = true;

  @Output() modelShow = false;//是否打开弹窗
  @Output() addResumeLabelmodelShow = false;//是否打开弹窗
  @Output() goAppropriate = new EventEmitter();
  @ViewChild('scrollMe') msgContent: ElementRef;

  public config: Config;
  public positionList = [];//职位列表
  public educationList = [];//学历列表
  public majorList = [];
  public keywordList = [];//搜索关键词匹配列表
  public scrollTop = 280;
  public openMoreFilt = true;
  public all_grade = [];
  public bannerList = [];//当前导航页
  public area1 = [];
  public area2 = [];
  public area3 = [];
  // 筛选条件--搜索
  public model = {
    keywords: '',//关键词
    major: '',//专业
    education: '',//学历
    status: '',//状态
    positionid: '',//职位id
    limit: 10,
    offset: 0,
    gender: '',
    native_place: '',
    is_curr_session: '',
    year: '',
    resume_number: '',
    mrid: '',
  }
  public status = {
    feedback_status: ''//反馈状态 posted：已投递viewed：已被查看interested：合适 improper：不合适
  }
  public searchModel = '';//搜索关键词
  public majorListOne = [];//一级专业列表
  public majorListTwo = [];//二级专业列表
  public majorOne = '';//专业类别一级
  public regionOne = '';//专业类别一级
  public resumeList = [];//简历列表
  public countList = 0;//列表数量
  public eachPageCount = 10;//每页显示数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  // public isPsitionList=false;
  @Input() isShow: false;//是否显示弹窗页面

  public mrdList: [];
  //是否全选
  public isAllSelete = false;
  public downLoadArr = [];
  public seleteDownLoadTypeModel = {
    show: false,
    step: 1,
    currentType: 'word',
  };
  public resumeNumberCount = 0;
  public resumeNumberShow = false;
  public jiebieList: any = [];
  public tabCount = {
    posted: 0,
    viewed: 0,
    interested: 0,
    improper: 0,
    interviewed: 0,
    invited: 0,
    total: 0
  };
  public initScroollNum = 0
  public platformApplyStatus = 0
  constructor(public dataApi: DataApi,
    public local: Local,
    private router: Router,
    public opera: Opera,
    public dialog: MatDialog,
    public toastr: ToastrService,
    public scrolltool: scrollTool,
    public events: Events,
    private activatedRoute: ActivatedRoute,
    public platformApplyNormalData: PlatformApply,
  ) {
    this.config = Config.getInstance();//实例化config
    activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams && queryParams.positionid) {
        this.model.positionid = queryParams.positionid
      } else {
        this.model.positionid = ""
      }
    })
  }

  async initData() {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams && queryParams.positionid) {
        this.model.positionid = queryParams.positionid;
      } else {
        this.model.positionid = "";
      }
      this.getSendResumeList();//获取简历投递列表
    })

  }

  //页面初始化
  async ngOnInit() {
    this.getJiebie();
    await this.getNewList();//获取职位列表数据
    // if (this.local.get('eachPageCount')) {
    //   this.eachPageCount = parseInt(this.local.get('eachPageCount'));
    // }
    // this.pageNumber = this.scrolltool.getVal('position_resume_cell_index');
    // this.model.offset = (this.pageNumber - 1) * this.eachPageCount;
    // this.model.limit = this.eachPageCount;
    let keepAliveData = this.scrolltool.getKeepAliveData()
    if(keepAliveData.pageKey === 'position-resume'){
      this.eachPageCount = keepAliveData.eachPageCount
      this.pageNumber = keepAliveData.pageNumber
      this.model = keepAliveData.model
    }
    this.opera.vipState((isPay) => {
      this.isShowMemberMoudel = isPay;
      if (!isPay) {
        // this.resumeNumber();
      }
    })
    this.getCardbanner();
    this.getCollegeCode();//获取高校字典
    this.getMajorList();//获取专业列表信息
    this.getEducationList();//获取学历列表信息
    this.getSendResumeList();//获取简历投递列表
    this.events.subscribe('updateResumeShouCangNot', () => {
      this.getSendResumeList();//获取简历投递列表
    });
    this.getAreaList();
    this.setGrade();
    this.getMridList();
    this.getResumeListCount();
    this.getPlatformApplyNormalData();
  }

  // tab 简历数量分类统计
  getResumeListCount() {
    this.dataApi.getResumeListCount().then((res: any) => {
      if (res.length) {
        res.forEach(item => {
          if (this.tabCount.hasOwnProperty(item.value)) {
            this.tabCount[item.value] = item.count;
          }
        });
      }
    }).catch((error) => {
      console.log('企业获取简历开放日期所在年份', error);
    });
  }

  // 企业获取简历开放日期所在年份
  getJiebie() {
    this.dataApi.getCircles().then((res: any) => {
      if (res.length) {
        let min = Math.min(...res);
        this.jiebieList = res.map(item => {
          return {
            text: item == min ? '往届' : `${item}届`,
            code: item
          }
        })
      }
    }).catch((error) => {
      console.log('企业获取简历开放日期所在年份', error);
    });
  }

  resumeNumber() {
    if(this.resumeNumberCount) return
    this.dataApi.resumeNumber().then((res: any) => {
      console.log('resumeNumber', res);
      if (res) {
        this.resumeNumberCount = res.value;
        if (res.value) {
          this.resumeNumberShow = true;
        }
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  resumeNumberHidden() {
    this.resumeNumberShow = false;
  }

  //点击产品
  product(item, type) {
    this.productModel.initData(item.alias, type);
    this.isproductShowModel = true;
  }

  productModelHidden(event) {
    this.isproductShowModel = false;
  }

  getMridList() {
    this.dataApi.getMridList().then((res: any) => {
      if (res) {
        let arry = [{
          Id: "",
          Name: "不限双选会",
        }]
        res = [...arry, ...res]
        this.mrdList = res;
        console.log(this.mrdList);
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  //去面试邀请
  goNotice(id, talentid) {
    this.modelShow = true;
    this.child.initData(id, true, talentid);
  }

  //接收子组件返回参数
  Modeldismiss(data) {
    this.modelShow = false;
    if (data) {
      //console.log("面试邀请返回信息-----model-delivery-resume");
      //console.log(data);
    }
  }

  //反馈状态
  setResumeStatus(id, status) {
    this.status.feedback_status = status;
    this.dataApi.setSendResumeStatus(id, status).then((res: any) => {
      // this.toastr.success("状态修改成功");
      for (let n of this.resumeList) {
        if (n.deliveryid == id) {
          n.feedback_status = status;
        }
      }
      if (status == 'interested') {
        setTimeout(() => {
          this.goAppropriate.emit()
        }, 1000)
      }
    }).catch((err) => {
      this.toastr.error(err);
    })
  }

  changeStatus(status) {
    this.model.offset = 0;
    this.model.status = status;
    this.setKeepAliveData()
    this.getSendResumeList(this.model.positionid)
  }

  //设置为不合适提醒
  setResumeImproper(id, name) {
    this.opera.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '简历处理为不合适',
        message: '简历处理为不合适后，15天内将不再接收 TA 的消息。如需修改，可到简历列表中重新处理。确定将【' + name + '的简历】 处理为不合适吗？',
        trueText: '确定',
        falseText: '再考虑一下'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      //console.log('返回值');
      //console.log(isConfirmed);
      this.opera.scrollTrue();
      if (isConfirmed) {
        this.setResumeStatus(id, 'improper')
      }
    });
  }

  //获取分页子组件返回数据
  getChildEvent(index) {
    console.log('子组件返回分页数-----college-resume', index, this.pageNumber);
    if (index == this.pageNumber) {
      return;
    }
    // this.scrolltool.setVal('position_resume_cell_index', index);
    if (index > 0) {
      this.pageNumber = index;
      this.model.offset = (this.pageNumber - 1) * this.eachPageCount;
      this.setKeepAliveData()
      this.getSendResumeList(this.model.positionid);//获取简历投递列表

    } else if (index == -1) {//上一页
      if (this.pageNumber == 1) {
        this.toastr.warning("当前为第一页");
      } else {
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber - 1) * this.eachPageCount;
        this.setKeepAliveData()
        this.getSendResumeList(this.model.positionid); // 获取简历投递列表        
      }
    } else if (index == -2) {//下一页
      if (this.pageNumber == this.pageCount) {
        this.toastr.warning("当前为最后一页");
      } else {
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber - 1) * this.eachPageCount;
        this.setKeepAliveData()
        this.getSendResumeList(this.model.positionid); // 获取简历投递列表
      }
    }
  }

  //修改每页显示的条数
  getpageCount(event) {
    // this.local.set('eachPageCount', event)
    console.log(event);
    this.model.offset = 0;
    this.model.limit = event;
    this.setKeepAliveData()
    if (this.pageNumber > 1) {
      this.getChildEvent(1);
    } else {
      this.getSendResumeList();//获取简历投递列表
    }
  }

  getSendResumeListSearch() {
    this.model.offset = 0;
    this.setKeepAliveData()
    this.getSendResumeList(this.model.positionid);
    var positionSelectDom = document.getElementById('position')
    if(this.model.positionid){
      positionSelectDom.classList.add('select_light')
    }else{
      positionSelectDom.classList.remove('select_light')
    }
  }

  //获取简历投递列表 参数非必填
  getSendResumeList(parmas?: any) {
    let that = this;
    that.keywordList = [];
    that.dataApi.getSendResumeList(that.model).then((res: any) => {
      if (res) {
        this.resumeList = res.list;
        this.downLoadArr = [];
        this.isAllSelete = false;
        that.pageCount = Math.ceil(res.count / this.eachPageCount);
        that.countList = res.count;
        console.log('获取简历投递列表', res, that.model);
        for (let val of this.resumeList) {
          val.selete = false;
          let arr = [];
          val.yixiang = "";
          val.youshiArr = [];
          for (let item1 of val.pro_skills) {
            val.yixiang += item1.text + "    "
            if (item1.contrast_list) {
              for (let youshi of item1.contrast_list) {
                val.youshiArr.push(youshi.text);
              }
            }
          }
        }
        var anchor = document.getElementById('divId');
        anchor.scrollIntoView(true)
      }
    }).catch((err) => {
      // console.log(444555,err)
      // this.resumeNumber();
      // enableProdMode();
      //console.log(err);
    })
  }


  //监听majorOne
  majorOneChange() {
    this.majorListTwo = [];
    for (let n of this.config.major) {
      if (n.parentcode == this.majorOne) {
        this.majorListTwo.push(n);
      }
    }
    this.model.major = '';
    this.setKeepAliveData()
  }

  //提示先选一级专业
  isShowTwo() {
    if (!this.majorOne) {
      this.toastr.warning("请先选择一级专业");
      return;
    }
  }

  //获取学历列表信息
  getEducationList() {
    if (this.dataApi.getEducationCode()) {
      this.educationList = this.dataApi.getEducationCode();
    } else {
      this.dataApi.getDictDate('education').then((res: any) => {
        this.educationList = res;
        this.dataApi.setEducationCode(res);
      }).catch((error) => {
        //console.log(error);
      });
    }
  }

  //获取一级专业列表
  getMajorListOne() {
    for (let n of this.config.major) {
      if (n.level == 2) {
        this.majorListOne.push(n);
      }
    }
  }

  getMajorList() {
    this.opera.loading();//加载动画
    this.dataApi.getagregationCount({}).then((res: any) => {
      // console.log('我要的取数据',res.facetgroups);
      if (res) {
        this.majorList = res.facetgroups;
        res.facetgroups.map((item) => {
          if (item.level == 1) {
            this.majorListOne.push(item)
          }
        })
        //this.majorListOne = res.facetgroups;
        this.getMajorListOne();//获取一级专业列表
      }
    }).catch((err) => {
      console.log(err);
      this.opera.loadiss();
    })
  }

  //获取高校字典
  getCollegeCode() {
    // 获取高校字典数据
    if (this.config.school.length < 1) {
      this.dataApi.getDictDate('school').then((res: any) => {
        if (res) {
          this.config.school = res;
        }
      }).catch((err) => {
      })
    }
  }

  //监听键盘事件
  inputKeyup(event) {
    let that = this;
    var keycode = window.event ? event.keyCode : event.which;
    if (keycode == 13) {
      that.goSearch(that.model.keywords);
    } else {
      that.getItems();
    }
  }

  //匹配高校名称字典
  getItems() {
    this.keywordList = [];
    if (!this.model.keywords) { return; }
    for (let s of this.config.school) {
      if (s.text.indexOf(this.model.keywords) >= 0) {
        this.keywordList.push(s);
      }
    }
  }

  //筛选
  goSearch(text) {
    this.model.keywords = text;
    this.setKeepAliveData()
    this.getSendResumeList();//获取简历列表
    this.keywordList = [];
  }

  //获取职位列表数据


  //-------获取线上数据
  async getNewList() {
    let model = {
      limit: 1000,
      offset: 0,
      status: '',
    }

    let res = await this.dataApi.getDropDownPositionList(model);
    if (res) {
      console.log("在期职位获取线上数据-----college-resume", res);
      console.log("在期职位获取线上数据-----college-resume", this.model.positionid);
      this.positionList = [];
      let deleteArr = [];
      let Arr = [];
      for (let item of res.list) {
        if (item.work_place_text) {
          item.name = item.name + ' [' + item.work_place_text + ']'
        }
        if (item.position_status == 'delete') {
          deleteArr.push(item);
        } else {
          Arr.push(item);
        }
      }

      Arr.sort(function (a, b) {
        var x = 'name'//要排序字段
        if (a[x] < b[x]) {
          return -1
        }
        if (a[x] > b[x]) {
          return 1
        }
        return 0;
      });
      deleteArr.sort(function (a, b) {
        console.log(a[x])
        var x = 'name'//要排序字段
        if (a[x] < b[x]) {
          return -1
        }
        if (a[x] > b[x]) {
          return 1
        }
        return 0;
      });
      let newArr = [...Arr, ...deleteArr];
      this.positionList = newArr;
      console.log('this.positionList', this.positionList);
    }
    // this.dataApi.getDropDownPositionList(model).then((res:any)=>{

    //   if(res) {
    //     this.positionList = new Array();
    //     let deleteArr = [];
    //     let Arr = [];
    //     for(let item of res.list ) {
    //       if(item.work_place_text){
    //         item.name =  item.name+' ['+item.work_place_text+']'
    //       }
    //       if( item.position_status == 'delete') {
    //         deleteArr.push(item);
    //       } else {
    //         Arr.push(item);
    //       }
    //     }

    //     Arr.sort(function (a, b) {
    //       var x = 'name'//要排序字段
    //       if (a[x] < b[x]) {
    //         return -1
    //       }
    //       if (a[x] > b[x]) {
    //         return 1
    //       }
    //       return 0;
    //     });
    //     deleteArr.sort(function (a, b) {
    //       console.log(a[x])
    //       var x = 'name'//要排序字段
    //       if (a[x] < b[x]) {
    //         return -1
    //       }
    //       if (a[x] > b[x]) {
    //         return 1
    //       }
    //       return 0;
    //     });
    //     let newArr = [...Arr,...deleteArr];
    //     this.positionList =  newArr;


    //   }
    //  }).catch((error) => {
    //    this.toastr.error(error);
    //  });
  }



  //-----跳转简历详情
  goDetails(n, index) {
    let that = this;
    this.opera._hmtPush("简历管理", "查看简历", "")
    that.scrolltool.setVal('position_resume_cell_scrollY', window.top.scrollY);
    that.config.isNavActive = 'college';
    that.config.isNavChildActive = 'college-resume';
    that.setResumeStatus(n.deliveryid, 'viewed');//简历状态修改为已查看
    that.router.navigate(['home/college-resumedetails'], {
      queryParams: {
        resumeid: n.resume_number,
        collegeid: n.collegeid,
        positionid: that.model.positionid,
        deliveryid: n.deliveryid
      }
    });

    // this.opera.checkMember((isPay)=>{
    //   if(isPay)
    //   {
    //     if(!n.ignore) {
    //       that.scrolltool.position_resume_cell_scrollY = window.top.scrollY;
    //       that.config.isNavActive='college';
    //       that.config.isNavChildActive='college-resume';
    //       that.setResumeStatus(n.deliveryid,'viewed');//简历状态修改为已查看
    //       that.router.navigate(['home/college-resumedetails'], {
    //         queryParams: {
    //           resumeid:n.resume_number,
    //           collegeid: n.collegeid,
    //           positionid:that.model.positionid
    //         }
    //       });
    //     }
    //   }
    //   else
    //   {
    //     //getcollegePublic
    //     //不是会员。检测学生高校是否有就业信息网，没有的话，提示购买会员。有的话可以查看简历
    //     // that.opera.checkMemberAlertWithType("goBuy")
    //     if(n.collegeid==null||n.collegeid=="") {
    //       if(!n.ignore) {
    //         that.scrolltool.position_resume_cell_scrollY = window.top.scrollY;
    //         that.config.isNavActive='college';
    //         that.config.isNavChildActive='college-resume';
    //         that.setResumeStatus(n.deliveryid,'viewed');//简历状态修改为已查看
    //         that.router.navigate(['home/college-resumedetails'], {
    //           queryParams: {
    //             resumeid:n.resume_number,
    //             collegeid: n.collegeid,
    //             positionid:that.model.positionid
    //           }
    //         });
    //       }
    //     } else {
    //       this.dataApi.getcollegePublic(n.collegeid).then((res:any)=>{
    //         this.opera.loadiss();
    //         if(res) {
    //           console.log('getcollegePublic',res.audit_switch);
    //           if(res.audit_switch.is_gwladd) {
    //             if(this.config.isShowPaySystem){
    //               that.opera.checkMemberAlertWithType("resume_delivery")
    //             }
    //             this.router.navigate(['home/tool-campus']);
    //           } else {
    //             if(!n.ignore) {
    //               that.scrolltool.position_resume_cell_scrollY = window.top.scrollY;
    //               that.config.isNavActive='college';
    //               that.config.isNavChildActive='college-resume';
    //               that.setResumeStatus(n.deliveryid,'viewed');//简历状态修改为已查看
    //               that.router.navigate(['home/college-resumedetails'], {
    //                 queryParams: {
    //                   resumeid:n.resume_number,
    //                   collegeid: n.collegeid,
    //                   positionid:that.model.positionid
    //                 }
    //               });
    //             }
    //
    //           }
    //         }
    //       }).catch((error) => {
    //         console.log(error);
    //       });
    //     }
    //   }
    // })
  }
  //设置学生一查看
  setStudentView(student) {
    //  //setStudentn.feedback_status == 'viewed'
    let arr = [];
    this.resumeList.map((item) => {

      if (item.feedback_status == 'posted' && student.resume_number == item.resume_number) {
        item.feedback_status = 'viewed';
      }
      arr.push(item);
    })
    this.resumeList = arr;
  }

  //立即沟通
  goChat(n, id) {
    this.opera.goChat(n, id);
  }
  //收藏
  goCollect(val) {
    let data = val;
    let that = this;
    val = this.opera.manageResumeArchived(val, () => {
      console.log('执行标签弹窗', data);
      that.resumeLabelModel.init(data);
      that.addResumeLabelmodelShow = true;
    });
  }
  async contentScroll(event) {
    console.log('contentScroll')
  }
  initScrooll() {
    console.log(666888,this.resumeList)
    if (this.resumeList.length) {
      this.initScroollNum = 0;
      this.scrolltool.initScrooll_cell_With_scrollY(
        this.scrolltool.getVal("position_resume_cell_scrollY")
      );
    } else if (this.initScroollNum < 10) {
      this.initScroollNum++;
      setTimeout(() => {
        this.initScrooll();
      }, 100);
    }
  }
  initScroollZero() {
    this.scrolltool.setVal('position_resume_cell_index', 1);
    window.scrollTo(0, 0);
  }
  shouCang(e, item) {
    e.stopPropagation();
    this.goCollect(item)
  }
  addResumeLabelmodeHidden(resume) {
    this.addResumeLabelmodelShow = false;
  }
  //打开关闭更多筛选
  openMoreFiltClick() {
    this.openMoreFilt = !this.openMoreFilt;
  }
  //区域字典
  getAreaList() {
    this.dataApi.getDictDate('region').then((res: any) => {
      if (res) {
        for (let i of res) {
          if (i.level === 1) {
            this.area1.push(i)
          } else if (i.level === 2) {
            this.area2.push(i)
          }
        }
      }
    })
  }
  //更多筛选籍贯一级变动
  regionOneChange() {
    let arr = [];
    let regionOne = this.regionOne;
    this.area2.map((item, index) => {
      if (item.parentcode == regionOne) {
        arr.push(item);
      }
    });
    this.area3 = arr;
    this.model.native_place = "";
    this.setKeepAliveData()
  }
  //设置界别
  setGrade() {
    let index = 20;
    let arr = [];
    let current = this.config.getYear();
    arr.push(current + 3);
    arr.push(current + 2);
    arr.push(current + 1);
    for (let n = 0; n < index; n++) {
      arr.push(current - n)
    }
    this.all_grade = arr;
  }
  cellClickDown(item, e) {
    e.stopPropagation();
    let arr = [];
    let isAll = true;
    this.resumeList.map((n) => {
      if (item.talentid == n.talentid) {
        n.selete = !n.selete;
      }
      if (!n.selete) {
        isAll = false;
      }
      arr.push(n);
    });
    let idarr = [];
    this.resumeList.map((e) => {
      if (e.selete) {
        idarr.push(e)
      }
    })
    this.resumeList = arr;
    this.downLoadArr = idarr;
    this.isAllSelete = isAll;
  }
  AllSeleteClick() {
    this.isAllSelete = !this.isAllSelete;
    let arr = [];
    let idarr = [];
    this.resumeList.map((n) => {
      n.selete = this.isAllSelete;
      arr.push(n);
      if (n.selete) {
        idarr.push(n);
      }
    })
    this.resumeList = arr;
    this.downLoadArr = idarr;
  }
  //点击批量导出
  downLoadChips() {
    let that = this;
    this.opera._hmtPush("简历管理", "批量导出简历", "")
    this.opera.checkMember((isPay) => {
      console.log('checkExportResume', isPay);
      if (isPay) {
        that.seleteDownLoadTypeModel.show = true;
      } else {
        // if(this.config.isShowPaySystem){
        //   that.opera.checkMemberAlertWithType("goBuy_downloadResumes")
        // }
        // this.router.navigate(['home/tool-campus']);
        this.product('', 'contactMe');
      }
    })
  }
  //标记不合适
  markImproper() {
    let that = this;
    this.opera._hmtPush("简历管理", "批量标记不合适", "")
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '简历处理为不合适',
        message: '简历处理为不合适后，15天内将不再接收 TA 的消息。如需修改，可到简历列表中重新处理。确定将选中的简历处理为不合适吗？',
        trueText: '确定',
        falseText: '再考虑一下'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      console.log('返回值', isConfirmed);
      if (!isConfirmed) {
        return;
      }
      Promise.all(this.downLoadArr.map(v=>this.dataApi.setSendResumeStatus(v.deliveryid, 'improper'))).then(() => {
        this.opera.loadiss();
        this.toastr.success('操作成功');
        this.getResumeListCount();
        this.getSendResumeList();
      }).catch((error) => {
        this.toastr.error('操作失败')
        this.opera.loadiss();
      });
    });
  }
  //切换下载的格式
  downLoadChange(type) {
    this.seleteDownLoadTypeModel.currentType = type;
  }
  closeDownLoad(isclose) {
    if (!isclose) {
      this.seleteDownLoadTypeModel.show = false;
      this.seleteDownLoadTypeModel.step = 1;
      return;
    }
    this.seleteDownLoadTypeModel.step = 2
  }
  //开始执行下载
  closeDownLoadHTTP() {
    let arr = [];
    this.downLoadArr.map((item) => {
      arr.push({ resume_number: item.resume_number, deliveryid: item.deliveryid })
    })
    //0 word,1 excel
    let data = {
      down_type: this.seleteDownLoadTypeModel.currentType == 'word' ? 0 : 1,
      list: arr,
    }

    this.opera.loading();
    this.dataApi.downLoadResumes(data).then((res: any) => {
      this.opera.loadiss();
      if (res) {
        console.log('导出简历', res);
        this.toastr.success('导出成功，请到 浏览器-下载 查看');
        this.seleteDownLoadTypeModel.show = false;
        this.seleteDownLoadTypeModel.step = 1;
      }
    }).catch((error) => {
      console.log(error);
      this.toastr.error('导出失败')
      this.opera.loadiss();
    });
  }

  //根据学校条件
  changeinput(event) {
    this.getSendResumeList(); // 获取简历投递列表
  }


  //获取card广告banner
  getCardbanner() {

    let that = this;
    this.dataApi.getCardBannerdata('jianli').then((res: any) => {
      if (res) {
        this.bannerList = res;
        setTimeout(() => {
          layui.use(['carousel', 'form'], function () {
            var carousel = layui.carousel
              , form = layui.form;
            //建造实例
            carousel.render({
              elem: '#test1'
              , width: '100%' //设置容器宽度
              , arrow: 'always', //始终显示箭头
              height: '160px',
              interval: 3000
              //,anim: 'updown' //切换动画方式
            });
            //找到两个按钮，设置样式，因为全局样式又一个Buttom里有一个样式会导致按钮错乱
            let leftbtn = document.getElementById("lay-type-left");
            let rightbtn = document.getElementById("lay-type-add");
            if (leftbtn) {
              leftbtn.style.padding = '0';
            }
            if (rightbtn) {
              rightbtn.style.padding = '0';
            }
          })
        }, 200)

      }
    }).catch((err) => {
      console.log(err);
    })
  }

  bannerClick(n) {
    console.log('bannerClick', n)
    if (n.links) {
      window.open(n.links, '_blank');
      return;
    }
  }

  nav02() {
    this.router.navigate(['home/position-resume-like']);
  }

  nav03() {
    this.router.navigate(['home/voiceCallRecord']);
  }

  nav04() {
    this.router.navigate(['home/positionPushRecord']);
  }

  nav05() {
    this.router.navigate(['home/activityInviteRecord']);
  }

  nav06() {
    this.router.navigate(['home/airCampHistoryReord']);
  }

  /** 设置缓存数据 */
  setKeepAliveData(){
    this.scrolltool.setKeepAliveData({
      pageKey:'position-resume',
      eachPageCount:this.eachPageCount,
      pageNumber:this.pageNumber,
      model:this.model
    })
  }

  getPlatformApplyNormalData(){
    this.platformApplyNormalData.get().then(res=>{
      this.platformApplyStatus = res.applyStatus
      if(!res.isCertified){
        this.resumeNumberShow = true;
      }
    }).catch((err) => {
      this.toastr.error(err);
    })
  }
}
