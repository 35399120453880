import { Component, OnInit ,Input, Output,ViewChild } from '@angular/core';
import { DataApi,Local,Constants,Opera,Config,FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute,Router } from  '@angular/router';
import { ModelCompanyPhoneComponent } from '../model-company-phone/model-company-phone.component';
import { ModelCropperComponent } from '../model-cropper/model-cropper.component';
import {regexManager} from "../../provider/regexManager";
//产品介绍/请校招顾问联系我
import { ProductModelComponent } from "../product-model/product-model.component";
import * as moment from 'moment';
declare let layui;
declare var window;
@Component({
  selector: 'app-company-account',
  templateUrl: './company-account.component.html',
  styleUrls: ['./company-account.component.css']
})
export class CompanyAccountComponent implements OnInit {
  //是否打开产品介绍/请校招顾问联系我 弹窗
  @Output() isproductShowModel = false;
  @ViewChild('productModel', { static: true }) 
  productModel: ProductModelComponent;

  @Output() modelShow = false;//是否打开弹窗
  @Output() modelTwoShow = false;//是否打开弹窗
  @ViewChild('phone', { static: true }) child: ModelCompanyPhoneComponent;
  @ViewChild('crop', { static: true }) child1: ModelCropperComponent;
  public config: Config;
  public myUser:any;
  //需要提交服务器的信息
  public userData = {
    name:'',
    post:'',
    gender:'01',//性别
    email:'',//工作邮箱
    weixin:'',
    loginEmail:'',
    emailCode:'',
    login_email:'',
    password:'',
    password_copy:'',
    email_actived:false,
  };
  public loginPhone='';//绑定手机号
  public mobile_actived = true;//绑定手机号
  public isName=false;//是否允许修改姓名
  public wechat:string;//微信号（企业信息里面）

  public updateEmailData={
    oldEmail:'',
    newEmail:'',
    newEmailCode:'',
  }
  public updatePswData={
    oldPsw:'',
    newPsw:'',
    okPsw:'',
    emailCode:'',
    type:'psw',
  }
  public bindEmailModel = {
      email:'',
      code:'',
      psw:'',
      psw2:''
  };
  public bindResumeEmail = '';
  public bindResumeEmailEnable = false
  public bindResumeEmailModel = {
    emailAddress:'',
    activeCode:'',
  }

  public updateLoginEmailModel=false;//修改邮箱
  public updateLoginEmailPsw=false;//修改密码

  public seePswType='password';
  public seePswType2='password';
  public seePswType3='password';

  public sendEmailText1='获取邮箱验证码';
  public sendEmailText2='获取邮箱验证码';
  public sendEmailText3='获取邮箱验证码';

  public isTuiJian = false;


  //是否显示绑定登录邮箱弹窗
  public showBingLoginEmailModel = false;
  // 是否显示绑定简历邮箱弹窗
  public showBindResumeEmailModel = false;

  public IsBindWeiChat = false
  public showDeleteBingWeiChatModel = false;
  public showBingWeiChatModel = false;
  public deleteWX = {
    phone:'',
    code:''
  }
  public countDown = "发送验证码"
  public isSending = false
  public timer;
  public weChatCode = "";
  public checkWeChatTimer = null
  public checkWeChatIndex = 60
  public contact_info_switch ={
    isData:false,
    is_public_mobile: true,
    is_public_wx: true,
    is_public_email: true
  }

  public timer1=null;
  public showExperienceModel = false;
  public experienceCode = '';
  public isExperence = false;
  public experienceModelTip = false;
  public is_trial_member_show = false;
  /** 密码强度 */
  public password_strength = 0;
  public password_strength_message = '';
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public opera:Opera,
    public formatDate: FormatDate,
     public regexManager:regexManager,
     public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
     }

  ngOnInit() {
    this.opera.initConfig('company','company-account');
    this.checkIdentity();//检查当前认证状态

    this.getUser();//获取用户信息
    this.getBindResumeEmail();// 获取绑定简历邮箱
    this.getDepartment();

    this.getIsBindWeiChat();

  }
  //获取企业信息
  getDepartment(){
    this.dataApi.getEmployment().then((res:any)=>{
      if(res) {
        this.isTuiJian = res.display_mobile;
        this.wechat = res.wechat;
        
        if(res.contact_info_list){
          this.contact_info_switch.isData = true
          this.contact_info_switch.is_public_email = res.contact_info_list.email.is_public
          this.contact_info_switch.is_public_mobile =res.contact_info_list.mobile.is_public
          this.contact_info_switch.is_public_wx = res.contact_info_list.wx.is_public
        }
        if(!res.is_trial_member && !res.member_info){
          this.is_trial_member_show = true;
        }
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  //修改手机号
  uploadPhone(){
    this.modelShow = true;
    this.opera.scrollTop();
    this.child.initData();
  }

  //接收子组件返回数据
  Modeldismiss(event){
    this.modelShow = false;
    this.opera.scrollTrue();
    if(event){
      //修改绑定手机号
      this.getUser();//
      this.myUser = this.dataApi.getLocalLoginUser();
      this.loginPhone = this.myUser.mobile;
    }
  }

  //获取企业认证信息
  checkIdentity(){
    this.dataApi.getLocalDepartment();
    this.config.department=this.dataApi.getLocalDepartment();
    if(this.config.department&&this.config.department.is_certified){
      this.isName = true;
    }else{
      this.dataApi.getPlatformApply().then((res:any)=>{
        if(res){
          this.config.lastIdentity=res;
          this.dataApi.setLastIdentity(this.config.lastIdentity);
          if(res.status==2){
            this.config.department.is_certified=true;
            this.dataApi.setLocalDepartment(this.config.department);
            this.isName = true;
          }else{
            this.isName = false;
          }
        }
      }).catch((error)=>{
        //console.log(error);
        this.toastr.error(error);
        this.isName = false;
      })
    }
  }

  //获取用户信息
  getUser(){
    this.dataApi.getUser().then((res:any)=>{
      if(res){
        this.myUser = res;
        this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量
        this.userData.name = this.myUser.name;
        this.userData.post = this.myUser.post;
        this.userData.weixin = this.myUser.weixin;
        this.userData.email = this.myUser.email;
        this.userData.gender = this.myUser.gender;
        this.userData.login_email = this.myUser.login_email==null?"":this.myUser.login_email
        this.userData.email_actived = this.myUser.email_actived
        this.loginPhone = this.myUser.mobile;
        this.mobile_actived = res.mobile_actived;
        this.updateEmailData.oldEmail = this.userData.login_email;
        if(this.userData.login_email) {
          this.bindEmailModel.email = this.userData.login_email;
        }
      }
    }).catch((error)=>{
      this.toastr.error(error);
    });
  }

  //验证邮箱
  checkEmail(){
    let phoneModel={
      format_type:2,//0:手机号，1：手机号&固话；2.邮箱
      data:this.userData.email
    }
    this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
      if(!res.value){
        this.toastr.warning('工作邮箱格式错误');
        return;
      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error('工作邮箱格式错误');
      return;
    })
  }

  //保存修改人才信息
  updateUser(){
    this.userData.weixin = this.wechat;
    let filter=/^[a-zA-Z\u4e00-\u9fa5]+$/;
    //---必填字段检查
    if(!this.userData.name){
       this.toastr.warning('请填写姓名');
       return;
    }
    if(!this.userData.email) {
      this.toastr.warning('请填写工作邮箱');
      return;
    }
    if(!this.userData.weixin) {
      this.toastr.warning('请填写微信');
      return;
    }
      if(!this.userData.post){
       this.toastr.warning('请填写职位');
       return;
    }else{
      if(!filter.test(this.userData.post)){
        this.toastr.warning("职位只能填写汉字或字母");
        return;
      }
    }

    //null值提交会被判断成字符串保存
    if(!this.userData.weixin){
      this.userData.weixin="";
    }

    this.dataApi.updateUser(this.userData).then((res:any)=>{
      this.myUser.name = this.userData.name;
      this.myUser.post = this.userData.post;
      this.myUser.weixin = this.userData.weixin;
      this.myUser.email = this.userData.email;
      this.myUser.gender = this.userData.gender;
      this.dataApi.setLocalLoginUser(this.myUser);
      if(this.userData.password) {
        this.userData.login_email = this.userData.loginEmail;
      }
      setTimeout(() => {
        this.toastr.success('保存成功');
      },600);
    }).catch((error)=>{
      try {
        if(error.indexOf('password请输入')>=0) {
          this.toastr.error('密码不规范，请输入8-20位数字和字母组合');
          return;
        }
      }catch(e){}
      setTimeout(() => {
        this.toastr.error(error);
      },600);
    })
  }
  //上传logo
  previewPic(event) {
    let imgData  = event.target.files[0];
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
        that.imageCropper(reader.result);
      };
    }
  }

  //裁剪插件
  imageCropper(imageData){
    this.modelTwoShow=true;
    this.child1.initData(imageData);
    this.opera.scrollTop();//页面滚动函数
  }

  //接收子组件返回数据
  ModelTwodismiss(event){
    this.modelTwoShow = false;
    this.opera.scrollTrue();
    if(event){
      this.uploadDepImg(event);
    }
  }

  //上传
  uploadDepImg(data){
    let imageData=data.replace(/^(data:image)\/png;base64,/g,'');
    imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
    this.opera.loading();
    this.dataApi.updateUserAvatar({value: imageData}).then((res:any)=>{
      this.myUser.avatar = res.value;
      this.config.loginUser.avatar=this.myUser.avatar;
      this.dataApi.setLocalLoginUser(this.config.loginUser);
      this.opera.loadiss();
      this.toastr.success("上传成功");
    }).catch((error)=>{
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
      this.opera.loadiss();
    })
  }

  /**
   * 发送邮件验证
   */
  sendEmail(type){
    // type 1 第一次绑定。2。修改绑定
    if(type==1) {
      console.log("userData.loginEmail",this.userData.loginEmail);
      this.userData.loginEmail = this.userData.loginEmail.trim()
      if(this.userData.loginEmail.length==0) {
        this.toastr.error("邮箱错误");return;
      }
      let data={
        email:this.userData.loginEmail,
      };
      if(this.sendEmailText1!='获取邮箱验证码') {
        this.toastr.error('60秒内不能重复发送')
        return;
      }
      let phoneModel={
        format_type:2,//0:手机号，1：手机号&固话；2.邮箱
        data:this.userData.loginEmail
      }
      this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
        if(res.value){
          this.dataApi.sendEmail(data).then((res:any)=>{
            console.log("发送邮箱",res,data);
            this.toastr.success('获取成功')
            let count = 60;
            this.sendEmailText1 = count + 's';
            this.timer1 = setInterval(()=>{
              count -= 1;
              this.sendEmailText1 = count + 's';
              if (count === 0) {
                clearInterval(this.timer1);
                this.sendEmailText1 = '获取邮箱验证码';
              }
            }, 1000);
          }).catch((err)=>{
            console.log("发送邮箱失败",err);
            this.toastr.error(err);return;
          })
        } else {
          this.toastr.warning('邮箱格式错误');
        }
      }).catch((err)=>{
        this.toastr.error(err);
      })
    }else {
      this.updateEmailData.newEmail = this.updateEmailData.newEmail.trim()
      if(this.updateEmailData.newEmail==null || this.updateEmailData.newEmail==''){
        this.toastr.error("邮箱错误");
        return;
      }
      if(this.updateEmailData.oldEmail==this.updateEmailData.newEmail) {
        this.toastr.error("原邮箱和新邮箱不能相同");
        return;
      }
      if(this.sendEmailText2!='获取邮箱验证码') {
        this.toastr.error('60秒内不能重复发送');
        return;
      }
      let accountid = this.local.get("accountid");
      if(accountid) {
        let phoneModel={
          format_type:2,//0:手机号，1：手机号&固话；2.邮箱
          data:this.updateEmailData.newEmail
        }
        this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
          if(res.value){
            let data={
              email:this.updateEmailData.newEmail,
            };
            this.dataApi.sendEmail(data).then((res:any)=>{
              this.toastr.success('发送成功');
              let count = 60;
              this.sendEmailText2 = count + 's';
              let timer = setInterval(()=>{
                count -= 1;
                this.sendEmailText2 = count + 's';
                if (count === 0) {
                  clearInterval(timer);
                  this.sendEmailText2 = '获取邮箱验证码';
                }
                if(this.updateEmailData.newEmail=='') {
                  clearInterval(timer);
                  this.sendEmailText2 = '获取邮箱验证码';
                }
              }, 1000);
            }).catch((err)=>{
              this.toastr.error(err);
            })
          } else {
            this.toastr.warning('邮箱格式错误');
          }
        }).catch((err)=>{
          console.log("发送邮箱失败",err);
          this.toastr.error(err);
        })
      } else {
        this.toastr.error("发送邮箱失败;错误码：E0001");
      }
    }
  }
  /**
   * 更新邮箱弹窗事件
   */
  updateEmail(){
    this.updateLoginEmailModel=true;
    this.updateEmailData.newEmail='';
  }

  /**
   * 更新密码弹窗事件
   */
  updatePsw(){
    this.updateLoginEmailPsw=true;
    this.updatePswData.okPsw='';
    this.updatePswData.oldPsw='';
    this.updatePswData.newPsw='';
    this.password_strength = 0;
    this.password_strength_message = '';
    this.sendEmailText2='获取邮箱验证码';
  }

  /**
   * 关闭更新密码/邮箱事件
   */
  closeModel(){
    this.updateLoginEmailModel=false;
    this.updateLoginEmailPsw=false;
    this.showBingLoginEmailModel = false;
    this.showBindResumeEmailModel = false;
  }

  /**
   * 保存更新邮箱确定按钮
   */
  saveEmail(){
    console.log("saveEmail",this.updateEmailData);
    if(this.updateEmailData.oldEmail==''||this.updateEmailData.newEmail==''||this.updateEmailData.newEmailCode=='') {
      this.toastr.error('信息不完整');
      return;
    }
    this.updateEmailData.newEmail = this.updateEmailData.newEmail.trim()
    let data = {
      email:this.updateEmailData.newEmail,
      vcode_text:this.updateEmailData.newEmailCode,
      request_type:"update",
    };
    this.dataApi.changeEmail(data).then((res:any)=>{
      console.log("修改成功",res);
      this.toastr.success('修改成功！');
      this.getUser();
      this.updateLoginEmailModel=false;
      this.userData.login_email = this.updateEmailData.newEmail;
      this.updateEmailData.newEmailCode='';
      this.updateEmailData.oldEmail=this.updateEmailData.newEmail;
      this.updateEmailData.newEmail='';
    }).catch((err)=>{
      console.log("修改邮箱失败",err);
      this.toastr.error(err);return;
    })
  }

  /**
   * 更新密码确定按钮
   */
  savePsw(){
    if(this.updatePswData.emailCode==''&&this.updatePswData.type=='email'){
      this.toastr.error("邮箱验证码为空！");
      return;
    }
    if(this.updatePswData.oldPsw==''&&this.updatePswData.type=='psw'){
      this.toastr.error("密码为空！");
      return;
    }
    if(this.updatePswData.newPsw==''||this.updatePswData.okPsw==''){
      this.toastr.error("密码为空！");
      return;
    }
    if (this.password_strength < 3) {
      this.toastr.error("新密码强度低");
      return;
    }
    if(this.updatePswData.newPsw!=this.updatePswData.okPsw) {
      this.toastr.error("两次密码不一致");
      return;
    }
    let data = {
      oldpassword:this.updatePswData.oldPsw,
      newpassword:this.updatePswData.newPsw,
    };
      if(this.updatePswData.type=='psw') {
        this.dataApi.changePsw(data).then((res:any)=>{
          console.log("修改成功",res);
          this.toastr.success('修改成功！');
          this.updateLoginEmailPsw = false;
        }).catch((err)=>{
          console.log("修改邮箱失败",err);
          this.toastr.error(err);return;
        })
      } else {
        let data = {
          "email": this.bindEmailModel.email,
          "reset_token":  this.updatePswData.emailCode,
          "newpassword": this.updatePswData.newPsw,
        }
        this.dataApi.findPswResetPsw(data).then((res:any)=>{
          console.log("修改密码",res,data);
          this.toastr.success('修改成功')
          this.updateLoginEmailPsw = false;

        }).catch((err)=>{
          console.log("修改失败",err);
          this.toastr.error(err);return;
        })
      }
  }
  changeSeePsw(index,type){
    if(index==1) {
      this.seePswType = type;
    }
    if(index==2) {
      this.seePswType2 = type;
    }
    if(index==3) {
      this.seePswType3 = type;
    }
  }
  accountLogout(){
    this.router.navigateByUrl('/home/account-logout-l');
  }
  //是否被推荐点击
  tuijianChanged(n){
    this.isTuiJian = !this.isTuiJian;
    let data = {value:this.isTuiJian}
    this.dataApi.setDepartmentTuiJian(data).then((res:any)=>{
      console.log("发送邮箱",res);
    }).catch((err)=>{
      console.log("发送邮箱失败",err);
      this.toastr.error(err);
    })
  }
  showBingLoginEmailModelClick(){
    this.showBingLoginEmailModel = true;
  }
  showBindResumeEmailModelClick(){
    this.showBindResumeEmailModel = true;
  }
  getBingEmailCode(){
    if(!this.bindEmailModel.email) {
      this.toastr.warning('请填写邮箱');
      return;
    }
    this.bindEmailModel.email = this.bindEmailModel.email.trim();
    let phoneModel={
      format_type:2,//0:手机号，1：手机号&固话；2.邮箱
      data:this.bindEmailModel.email
    }
    if(this.sendEmailText2!='获取邮箱验证码') {
      this.toastr.error('60秒内不能重复发送');
      return;
    }
    this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
      if(res.value){
        let data={ email:this.bindEmailModel.email };
        this.dataApi.sendEmail(data).then((res:any)=>{
          console.log("发送邮箱",res,data);
          this.toastr.success('获取邮箱验证码成功')
          let count = 60;
          this.sendEmailText2 = count + 's';
          this.timer1 = setInterval(()=>{
            count -= 1;
            this.sendEmailText2 = count + 's';
            if (count === 0) {
              clearInterval(this.timer1);
              this.sendEmailText2 = '获取邮箱验证码';
            }
          }, 1000);
        }).catch((err)=>{
          console.log("发送邮箱失败",err);
          this.toastr.error(err);return;
        })
      } else {
        this.toastr.error('邮箱格式错误');
      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error('工作邮箱格式错误');
      return;
    })
  }
  getBingEmailCode_reset(){
    if(!this.bindEmailModel.email) {
      this.toastr.warning('请填写邮箱');
      return;
    }
    let phoneModel={
      format_type:2,//0:手机号，1：手机号&固话；2.邮箱
      data:this.bindEmailModel.email
    }
    if(this.sendEmailText2!='获取邮箱验证码') {
      this.toastr.error('60秒内不能重复发送');
      return;
    }
    this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
      if(res.value){
        let data={
          email:this.bindEmailModel.email,
        };
        this.dataApi.findPswSendEmailapp(data).then((res:any)=>{
          this.toastr.success('获取邮箱验证码成功')
          let count = 60;
          this.sendEmailText2 = count + 's';
          this.timer1 = setInterval(()=>{
            count -= 1;
            this.sendEmailText2 = count + 's';
            if (count === 0 || count < 0) {
              clearInterval(this.timer1);
              this.sendEmailText2 = '获取邮箱验证码';
            }
          }, 1000);
        }).catch((err)=>{
          console.log("发送邮箱失败",err);
          this.toastr.error(err);return;
        })
      } else {
        this.toastr.error('邮箱格式错误');
      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error('工作邮箱格式错误');
      return;
    })
  }
  //提交绑定邮箱
  commitBindLoginEmail(){
    if(!this.bindEmailModel.email) {
      this.toastr.error('请输入邮箱');return;
    }
    if(!this.bindEmailModel.code) {
      this.toastr.error('请输入邮箱验证码');return;
    }
    if(!this.bindEmailModel.psw) {
      this.toastr.error('请输入登录邮箱密码');return;
    }
    if(this.password_strength<3) {
      this.toastr.error('登录邮箱密码强度低');return;
    }
    if(!this.bindEmailModel.psw2) {
      this.toastr.error('请输入确认密码');return;
    }
    if(this.bindEmailModel.psw != this.bindEmailModel.psw2) {
      this.toastr.error('密码不一致');return;
    }
    this.bindEmailModel.email = this.bindEmailModel.email.trim()
      let pswData = {
        email:this.bindEmailModel.email,
        vcode_text:this.bindEmailModel.code,
        password:this.bindEmailModel.psw
      }
      this.dataApi.userBindEmailSetPsw(pswData).then((res:any)=>{
        this.toastr.success('绑定成功');
        this.showBingLoginEmailModel = false;
        console.log('res',res);
        this.userData.login_email = this.bindEmailModel.email;
        this.userData.email_actived = true;
      }).catch((err)=>{
        console.log("修改失败",err);
        this.toastr.error(err);return;
      })
  }
  changePswType(){
    this.updatePswData.type=  this.updatePswData.type=="email"?"psw":"email"
  }

  getIsBindWeiChat(){
    let accountid= this.local.get('accountid');
    let loginUser= this.local.get('loginUser');
    if(loginUser) {
      let user = JSON.parse(loginUser)
      if(user.mobile) {
        this.deleteWX.phone = user.mobile
      }
    }
    this.dataApi.getComopanyIsBingWeiChat(accountid).then((res:any)=>{
      this.IsBindWeiChat = true
    }).catch((error)=>{
      this.IsBindWeiChat = false
    })
  }

  showBindWeiChatClick(){
    if(this.IsBindWeiChat){
      this.deleteWX.code = "";
      this.showDeleteBingWeiChatModel = true
    }else {
      console.log('展示二维码绑定微信')
      if(this.local.get('department')) {
        let department = this.local.get('department');
        let departmentJSON = JSON.parse(department);
        let departmentid = departmentJSON.accountid;
        let data = {departmentid: departmentid};
        //getWeiChatCode
        this.dataApi.getWeiChatCode(data).then((res:any)=>{
          if(res){
            this.weChatCode = res.value
            this.showBingWeiChatModel = true
            this.startCheckBingWx()
          }
        }).catch((error)=>{
          this.toastr.error('获取失败，请稍后再试');
        });
      }
    }
  }

  closeBingWeiChat(){
    this.showDeleteBingWeiChatModel = false
    this.showBingWeiChatModel = false
    this.countDown = '发送验证码';
    if(this.timer){
      clearInterval(this.timer);
    }
  }

  sendSms(){
    if(this.countDown !="发送验证码"){
      this.toastr.warning(this.countDown+'后重新发送');
      return
    }
    this.postMobileSmsVcode()
  }

  // 发送验证码(+验证手机号是否可用)
  postMobileSmsVcode () {
    if(!this.deleteWX.phone){
      this.toastr.warning('手机号码格式不正确');
      return
    }
    let phoneModel={
      format_type:0,//0:手机号，1：手机号&固话；2.邮箱
      data:this.deleteWX.phone
    }
    this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
      if(res.value){
        this.sendPhoneCode();//发送验证码
      }else{
        this.toastr.warning('手机号码格式不正确');
        this.deleteWX.phone = '';
      }
    }).catch((err)=>{
      this.toastr.warning(err);
    })
  };

  // 发送验证码(+验证手机号是否可用)
  sendPhoneCode(){
    this.opera.loading()
    this.dataApi.postMobileSmsVcodeApp({//获取验证码
      value: this.deleteWX.phone
    }).then((res:any)=>{
      this.opera.loadiss()
      this.toastr.success('验证码发送成功！');
      let count = 59;
      this.countDown = count + 's';
      this.isSending = false;
      this.timer = setInterval(()=>{
        count -= 1;
        this.countDown = count + 's';
        if (count === 0) {
          clearInterval(this.timer);
          this.countDown = '发送验证码';
        }
      }, 1000);
    }).catch((error)=>{
      //console.log(error);
      this.opera.loadiss()
      this.toastr.error(error);
    });
  };

  startCheckBingWx() {
    let accountid = this.local.get('accountid');
    this.checkWeChatIndex = 60
    if(this.checkWeChatTimer){
      clearInterval(this.checkWeChatTimer)
    }
   this.checkWeChatTimer =  setInterval(()=>{
     this.checkWeChatIndex-=1;
     if(this.checkWeChatIndex <=0){
       this.checkWeChatIndex = 61
       clearInterval(this.checkWeChatTimer)
     }
     this.dataApi.getComopanyIsBingWeiChat(accountid).then((res:any)=>{
       console.log('已绑定')
       this.showBingWeiChatModel = false
       this.checkWeChatIndex = 60
       if(this.checkWeChatTimer){
         clearInterval(this.checkWeChatTimer)
       }
       this.IsBindWeiChat = true;
       this.toastr.success('绑定成功')
     }).catch((error)=>{
       console.log('未绑定')
     })
    },1000)
  }

  closeCheckWeiChat(){
    this.checkWeChatIndex = 61;
    this.showBingWeiChatModel = false
    if(this.checkWeChatTimer){
      clearInterval(this.checkWeChatTimer)
    }
  }

  //解除温馨绑定
  deleteWxBing(){
    if(!this.deleteWX.phone) {
      this.toastr.warning('请输入绑定手机号')
      return;
    }
    if(!this.deleteWX.code) {
      this.toastr.warning('请输入验证码')
      return;
    }
    let data = {
      mobile:this.deleteWX.phone,
      vcode_text:this.deleteWX.code
    }
    this.dataApi.unBindWeChat(data).then((res:any)=>{
      this.toastr.success('解绑绑定')
      this.showDeleteBingWeiChatModel = false
      this.IsBindWeiChat = false
    }).catch((error)=>{
      console.log('未绑定')
      this.toastr.error(error)
    })
  }
  //这是用户信息是否公开
  setUserInfoOpen(type,isOpen){
    let that = this
    layui.use('layer', function(){
      var layer = layui.layer;
     let alert = layer.confirm('确定修改吗？', {
        btn: ['确定','取消'],
       title:'设置'
      }, function(){
        layer.close(alert);
         let data
         if(type=='email') {
           data ={
             type:'email',
             is_public:isOpen
           }
         }
         if(type=='phone') {
           data ={
             type:'mobile',
             is_public:isOpen
           }
         }
         if(type=='wx') {
           data ={
             type:'wx',
             is_public:isOpen
           }
         }
         that.dataApi.setDepartmentSwitchContactinfo(data).then((res:any)=>{
           that.toastr.success('设置成功')
           if(type=='email') {
             that.contact_info_switch.is_public_email = isOpen
           }
           if(type=='phone') {
             that.contact_info_switch.is_public_mobile = isOpen
           }
           if(type=='wx') {
              that.contact_info_switch.is_public_wx = isOpen
           }
         }).catch((error)=>{
           that.toastr.error(error)
         })
      }, function(){
      });

    });
  }


 async exchangeCode(){
    //未认证
    if(this.config.lastIdentityNavSatus!=2){
      this.router.navigateByUrl('guide-audit');
      return
    }
    //是否为体验会员
    let department = await this.dataApi.getLocalDepartment();    
    if(!department.is_trial_member){
      this.showExperienceModel=true;
      this.isExperence = false;
      this.experienceCode = '';
    }else{
      this.experienceModelTip = true;
    }
  }

  //兑换
  exchangeBtn(){
    if(!this.experienceCode){
      this.toastr.warning('请输入体验码');
      return
    }
    this.dataApi.exchangeCode(this.experienceCode).then((res:any)=>{
      this.isExperence = true;
    }).catch((error)=>{
      this.toastr.warning(error);
      console.log('兑换',error)
    })
  }

  closBtn(){
    this.showExperienceModel = false;
  }

  productModelHidden(event){
    this.isproductShowModel = false;
  }

   //点击产品
   product(item,type){
    this.productModel.initData(item.alias,type);
    this.isproductShowModel = true;
  }

  //去公司主页
  goComHome(){
    this.router.navigateByUrl('/home/company-details');
  }
  //获取绑定简历邮箱
  getBindResumeEmail(){
    this.dataApi.getBindResumeEmail().then(res=>{
      console.log('绑定邮箱',res)
      this.bindResumeEmail = res.email
      this.bindResumeEmailEnable = res.enable
      // if(res.enable&&res.email){
      //   this.bindResumeEmail = res.email;
      // }else{
      //   this.bindResumeEmail = '';
      // }
    })
  }
  // 获取绑定简历邮箱验证码
  getBindResumeEmailCode(){
    if(!this.bindResumeEmailModel.emailAddress) {
      this.toastr.warning('请填写邮箱');
      return;
    }
    this.bindResumeEmailModel.emailAddress = this.bindResumeEmailModel.emailAddress.trim();
    if(this.sendEmailText3!='获取邮箱验证码') {
      this.toastr.error('60秒内不能重复发送');
      return;
    }
    this.dataApi.sendResumeEmailActive(this.bindResumeEmailModel.emailAddress).then((res:any)=>{
      this.toastr.success('获取邮箱验证码成功')
        let count = 60;
        this.sendEmailText3 = count + 's';
        this.timer1 = setInterval(()=>{
          count -= 1;
          this.sendEmailText3 = count + 's';
          if (count === 0) {
            clearInterval(this.timer1);
            this.sendEmailText3 = '获取邮箱验证码';
          }
        }, 1000);
    }).catch((err)=>{
      console.log(err);
      this.toastr.error(err);
      return;
    })
  }
  //提交绑定简历邮箱
  commitBindResumeEmail(){
    if(!this.bindResumeEmailModel.emailAddress) {
      this.toastr.error('请输入邮箱');return;
    }
    if(!this.bindResumeEmailModel.activeCode) {
      this.toastr.error('请输入邮箱验证码');return;
    }
    this.bindResumeEmailModel.emailAddress = this.bindResumeEmailModel.emailAddress.trim()
    this.dataApi.setBindResumeEmail(this.bindResumeEmailModel).then((res:any)=>{
        this.toastr.success('绑定成功');
        this.showBindResumeEmailModel = false;
        this.getBindResumeEmail()
    }).catch((err)=>{
      console.log("绑定失败",err);
      this.toastr.error(err);
    })
  }
  // 切换简历转发邮箱启用状态
  changeBindResumeEmailEnable(){
    let that = this
    layui.use('layer', function(){
      var layer = layui.layer;
      layer.confirm(`确定要${that.bindResumeEmailEnable?'禁用':'启用'}简历转发邮箱？`, {
        btn: ['确定','取消'] //按钮
      }, function(){
        that.dataApi.setBindResumeEmailEnable(!that.bindResumeEmailEnable).then((res:any)=>{
          that.getBindResumeEmail()
          layer.msg('操作成功', {icon: 1})
        }).catch((err)=>{
          console.log(err);
          layer.msg(err, {icon: 2});
        })
      }, function(){
        layer.msg('取消操作', {icon: 2});
      });

    });
  }
  /** 密码失焦 */
  passwordBlur(e) {
    let password = e.target.value
    if(!password) {
      this.password_strength_message = '';
      this.password_strength = 0
      return
    }
    this.dataApi.checkSafetyPassword(password).then((res: any) => {
      this.password_strength = res.score + 1;
      this.password_strength_message = ['','密码强度极低，极易被破解','密码强度低，可不太安全','密码强度中等，存在一定风险','密码强度高，安全性较好','密码强度极高，安全性强'][this.password_strength]
      if(!this.password_strength){
        this.password_strength_message = res.message
      }
    });
  }
}
