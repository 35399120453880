// 企业信息
import { Injectable } from "@angular/core";
import { DataApi } from "../provider/dataApi";

@Injectable()
export class Department {
  public data: any;
  constructor(public dataApi: DataApi) {}
  /**
   * 获取企业数据
   * @param refType 1：无需刷新；2：仅刷新当前数据；3：刷新所有数据
   * @returns
   */
  public async get(refType = 1) {
    let _refType = refType;
    if (_refType === 1 && this.data) return this.data;
    if (_refType === 2) _refType = 1;
    // 获取依赖数据

    // 获取自身远端数据

    // 处理逻辑
    let res = await this.dataApi.getEmployment();
    this.data = { apiData: res };
    // 返回数据
    return this.data;
  }
}
