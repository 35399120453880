<div class="top-header">
    <div class="center">
        <span (click)="cancel()" class="backMain flex_l"><img src="/assets/images/v3/headerReturnIcon.png" />返回登录</span>
    </div>
</div>


<div class="contentPsw">

    <p class="titlePsw">找回密码</p>

    <div style="width: 100%" *ngIf="tap==1">
        <div class="d_f sign-list">
            <input type="text" placeholder="请填写邮箱" [(ngModel)]="findPswModel.email" class="f_1">
        </div>

        <div class="d_f sign-list">
            <input [(ngModel)]="vcodeImg" class="f_1" type="text" placeholder="请输入图片验证码" />
            <img (click)="getCode()" *ngIf="imgCode" [src]="imgCode" class="imgCode">
            <button (click)="getCode()" *ngIf="!imgCode" class="custom_btn_primary emailBtn">点击获取验证码</button>
        </div>

        <div class="d_f sign-list">
            <input type="text" class="f_1" placeholder="请填写邮箱验证码" [(ngModel)]="findPswModel.emailCode">
            <button class="custom_btn_primary emailBtn" (click)="getEmailCode()">{{sengCodeBtn}}</button>
        </div>
        <div class="sign-list flex_r">
            <span style="cursor: pointer;color: #5E4FFF" (click)="gotoFindAccount()"> >> 账号找回</span>
        </div>
        <div class="d_f jc_sb">
            <button class="custom_btn_primary finishBtn" (click)="cancel()">取消</button>
            <button class="custom_btn_primary finishBtn" (click)="nextTap(2)">下一步</button>
        </div>
    </div>

    <div style="width: 100%" *ngIf="tap==2">
        <div class="d_f sign-list">
            <input type="password" class="f_1" placeholder="请填写新密码" [(ngModel)]="findPswModel.oldpsw"  (blur)="passwordBlur($event)">
        </div>
        <div class="d_f fw_w" [class]="'strength_box'+password_strength"><div [class]="'password_strength'+password_strength"></div><div class="strength_message">{{password_strength_message}}</div></div>
        <div class="d_f sign-list">
            <input type="password" class="f_1" placeholder="再次输入新密码" [(ngModel)]="findPswModel.newpsw">
        </div>
        <div class="d_f jc_sb">
            <button class="custom_btn_primary finishBtn" (click)="nextTap(1)">上一步</button>
            <button class="custom_btn_primary finishBtn" (click)="finishClick()">完成</button>
        </div>
    </div>

</div>